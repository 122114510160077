/*
  Startpage hero dance types
*/

.hero{
  background: #333;
  -webkit-transition: all 0.3s;
  transition: all 0.5s;

  .main-img{
  }
  .dance-types{
    width: 100%;
    height: 100%;
    position: relative;

    .dance-type{
      position:absolute;
      width:220px;
      height:70px;
      font-size:32px;
      font-weight: 500;
      color:#fff;
      font-family: @titleFont;
      letter-spacing: 1px;
      line-height: 25px;
      z-index:20;

      a{
        color:#fff;
        text-decoration:none;
      }
      span{
        font-size: 20px;
        display:block;
        text-align: right;
        letter-spacing: 0.21px;
      }
    }

    .dance-selector{
      position:absolute;
      z-index:12;
      width: 420px;
      height: 80px;
      // background: #ff0000;
      // opacity:0;
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff0000+49,ff0000+100&1+7,0+100 */
      background: -moz-linear-gradient(left, rgba(255,0,0,1) 7%, rgba(255,0,0,0.55) 49%, rgba(255,0,0,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(255,0,0,1) 7%,rgba(255,0,0,0.55) 49%,rgba(255,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(255,0,0,1) 7%,rgba(255,0,0,0.55) 49%,rgba(255,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff0000', endColorstr='#00ff0000',GradientType=1 ); /* IE6-9 */

    }

  }
}

/*
  Dance selector types
*/
  .dance-selector-right-bg{
      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff0000+0,ff0000+51&0+0,1+93 */
    background: -moz-linear-gradient(left, rgba(255,0,0,0) 0%, rgba(255,0,0,0.55) 51%, rgba(255,0,0,1) 93%) !important; /* FF3.6-15 */
    background: -webkit-linear-gradient(left, rgba(255,0,0,0) 0%,rgba(255,0,0,0.55) 51%,rgba(255,0,0,1) 93%) !important; /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, rgba(255,0,0,0) 0%,rgba(255,0,0,0.55) 51%,rgba(255,0,0,1) 93%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ff0000', endColorstr='#ff0000',GradientType=1 ) !important; /* IE6-9 */
  }


  .dance-selector.tarsastanc{
    top: 115px;
    left: 20px;
    transform: rotate(8deg);
    opacity: 0.8 !important;
  }
  .dance-selector.salsa{
    top: 210px;
    left: 20px;
    transform: rotate(3deg);
    opacity: 0.8 !important;
  }
  .dance-selector.swing,
  .dance-selector.west-coast-swing{
    top: 310px;
    left: 20px;
    transform: rotate(-3deg);
    opacity: 0.8 !important;
  }
  .dance-selector.zumba{
    top: 400px;
    left: 20px;
    transform: rotate(-8deg);
    opacity: 0.8 !important;
  }


  // .dance-selector.rocky{
  //   top: 115px;
  //   right: 20px;
  //   transform: rotate(-8deg);
  //   opacity: 0.8 !important;
  //   .dance-selector-right-bg;

  // }
  // .dance-selector.bachata{
  //   top: 210px;
  //   right: 20px;
  //   transform: rotate(-3deg);
  //   opacity: 0.8 !important;
  //   .dance-selector-right-bg;
  // }
  // .dance-selector.fellepes{
  //   top: 310px;
  //   right: 20px;
  //   transform: rotate(3deg);
  //   opacity: 0.8 !important;
  //   .dance-selector-right-bg;
  // }
  // .dance-selector.eskuvoi{
  //   top: 390px;
  //   right: 20px;
  //   transform: rotate(8deg);
  //   opacity: 0.8 !important;
  //   .dance-selector-right-bg;
  // }
  // .dance-selector.szalagavato{
  //   // if right side last element
  //   // top: 390px;
  //   // right: 20px;
  //   top: 300px;
  //   right: 10px;
  //   transform: rotate(8deg);
  //   opacity: 0.8 !important;
  //   .dance-selector-right-bg;
  // }

  .dance-selector.bachata{
    top: 115px;
    right: 20px;
    transform: rotate(-8deg);
    opacity: 0.8 !important;
    .dance-selector-right-bg;
  }
  .dance-selector.rocky{
    top: 210px;
    right: 20px;
    transform: rotate(-3deg);
    opacity: 0.8 !important;
    .dance-selector-right-bg;
  }
  .dance-selector.eskuvoi{
    top: 300px;
    right: 10px;
    transform: rotate(8deg);
    opacity: 0.8 !important;
    .dance-selector-right-bg;
  }
  .dance-selector.szalagavato{
    top: 390px;
    right: 20px;
    transform: rotate(8deg);
    opacity: 0.8 !important;
    .dance-selector-right-bg;
  }
  .dance-selector.fellepes{
    top: 310px;
    right: 20px;
    transform: rotate(3deg);
    opacity: 0.8 !important;
    .dance-selector-right-bg;
  }
/*
  Dance type titles in hero
*/

.dance-type.tarsastanc{
  top: 130px;
  left: 30px;
  transform: rotate(8deg);
}
.dance-type.salsa{
  top: 230px;
  left: 30px;
  transform: rotate(3deg);
}
.dance-type.swing,
.dance-type.west-coast-swing{
  top: 330px;
  left: 30px;
  transform: rotate(-3deg);
}
.dance-type.zumba{
  top: 440px;
  left: 40px;
  transform: rotate(-8deg);
}

.dance-type.bachata{
  top: 130px;
  right: 50px;
  transform: rotate(352deg);
}
.dance-type.rocky{
  top: 230px;
  right: 30px;
  transform: rotate(357deg);
}
.dance-type.eskuvoi{
  top: 330px;
  right: 30px;
  transform: rotate(8deg);
}
.dance-type.szalagavato{
  // top: 410px;
  // right: 60px;
  top: 410px;
  right: 60px;
  transform: rotate(8deg);
}
// .dance-type.fellepes{
//   top: 338px;
//   right: 30px;
//   transform: rotate(3deg);
// }



/*
  Hero styles for each dance style
*/
@heroZumbaColor:#28a372;
@heroTarsastancColor:#FD6100;
@heroSalsaColor:#AC0302;
@heroSwingColor:#ff5ac7;
@heroRockyColor:#00AEFF;
@heroBachataColor:#b732d9;
@heroFellepesColor:#e5d5a0;
@heroSzalagavatoColor:#e5d5a0;
@heroEskuvoColor:#cfdde5;

.hero.zumba{
  background-color: @heroZumbaColor;
  .main-img{
    background-image: url('../images/dance-zumba.png');
  }
}
.hero.tarsastanc{
  background-color: @heroTarsastancColor;
  .main-img{
    background-image: url('../images/dance-tarsas.png');
  }
}
.hero.salsa{
  background-color: @heroSalsaColor;
  .main-img{
    background-image: url('../images/dance-salsa.png');
  }
}
.hero.swing, .hero.west-coast-swing{
  // background-color: @heroSwingColor;
  background-image: url('../images/swing-hero-bg.jpg');
  background-repeat: repeat;
  .main-img{
    background-image: url('../images/dance-westcoast.png');
  }
}
.hero.rocky{
  background-color: @heroRockyColor;
  .main-img{
    background-image: url('../images/dance-rocky.png');
  }
}
.hero.bachata{
  background-color: @heroBachataColor;
  .main-img{
    background-image: url('../images/dance-batchata.png');
  }
}
.hero.fellepes{
  background-color: @heroFellepesColor;
  .main-img{
    background-image: url('../images/dance-fellepes.png');
  }
}
.hero.szalagavato{
  background-color: @heroSzalagavatoColor;
  .main-img{
    background-image: url('../images/dance-szalagavato.png');
  }
}
.hero.eskuvoi{
  background-color: @heroEskuvoColor ;
  .main-img{
    background-image: url('../images/dance-eskuvoi.png');
  }
}

