/*
  Startpage hero styles
*/

.hero{ 
  width:100%;
  height: 680px;
  position: relative;  
  background: #f2f2f2;

  .top-shade{
    // background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='100,0 0,100 0, 0' style='fill:%23f2f2f2' /%3E%3C/svg%3E");
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    // content: '';
    // display: block;
    // height: 100px;
    // position: absolute;
    // width: 100%;
    // left: 0;
    // top:0px;

    // --  IMG solution for the shade
    background-image: url("../images/goldance-hero-top.png");
    background-repeat: no-repeat;
    background-position: top;
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    left: 0;
    top:-25px;   
  }
  
  .bottom-shade{
    // --  CS solution for the shade
    // background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,100 100,0 100,100' style='fill:%23f2f2f2' /%3E%3C/svg%3E");
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    // content: '';
    // display: block;
    // height: 100px;
    // position: absolute;
    // width: 100%;
    // left: 0;
    // bottom:0px;
    
    // --  IMG solution for the shade
    background-image: url("../images/goldance-hero-bottom.png");
    background-repeat: no-repeat;
    background-position: bottom;
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
    left: 0;
    bottom:0px;     
  }

  .highlight{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+47&1+0,0+46 */
    background: -moz-radial-gradient(center, ellipse cover, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 70%, rgba(255,255,255,0) 07%); /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 70%,rgba(255,255,255,0) 07%); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 70%,rgba(255,255,255,0) 07%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      width:100%;
      height:585px;
      display: block;  
      z-index:15;
  }
  
  .main-img{
    position: absolute;
    top: 0px;
    background-position:  center;
    background-size: contain;
    background-repeat: no-repeat;    
    width:100%;
    height:505px;
    z-index:15;
  }  
}

.hero-mobile{
  width:100%;
  min-height: 250px;
  position: relative;  
  background: #fff;

  .top-shade{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='100,0 0,100 0, 0' style='fill:%23f2f2f2' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    display: block;
    height: 100px;
    position: absolute;
    // z-index: 100;
    width: 100%;
    left: 0;
    top:0px;;   
  }
  
  .bottom-shade{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,100 100,0 100,100' style='fill:%23f2f2f2' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    display: block;
    height: 100px;
    position: absolute;
    // z-index: 100;
    width: 100%;
    left: 0;
    bottom:0px;;    
  }
}
