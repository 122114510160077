/*
  Application discount-ul list style
*/

.discount-deals {
  .fa-li{
    color: #ff7e00;
  }
  li{
    font-size: 13px;
    margin-top: 10px;

    strong{
      display: block;;
    }
  }
}
