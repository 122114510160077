/*
  Application start-here style
*/

.start-here{
  width:100%;
  // height:275px;
  padding-bottom: 20px;
  margin-top:-40px;
  position:relative;
  // background: #F2F2F2;
  background: transparent;

  img{
    width: 100%;
    height: auto;
  }

  h2{
    text-align: center;
    font-family: @titleFont;
    font-size: 24px;
    color:#5e5e5e;

    a{
      color:#5e5e5e;
    }

    span{
      color:#000;
    }
  }

  .icon-item{
      margin-top:40px;

      .icon{
        width:100%;
        height:80px;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;

        a{
          display: block;
          width:100%;
          height:100%;
        }
      }
      .icon.kalkulator{
        background-image: url('../images/ico-calc.png');
      }
      .icon.arak{
        background-image: url('../images/ico-araink.png');
      }
      .icon.akciok{
        background-image: url('../images/ico-akcioink.png');
      }
      .icon.orarend{
        background-image: url('../images/ico-orarend.png');
      }
    }
    .title{
      padding-top:5px;
      font-size: 12px;
      font-family: @titleFont;
      font-weight: 300;
      text-align: center;
      width:100%;
    }
}