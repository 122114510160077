/*
  Application price-calculator-component style
*/

.berlet-info{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  .card-single{
    // width: 90%;
    width: 340px;
    margin-top: 25px;
    text-align: center;

    .card-item{
      width: 100%;
      display: inline-block;
      position: relative;
      padding-bottom: 10px;

      .image{
        width: 100%;
        height: 129px;
        box-shadow: 0 4px 2px -2px gray;
        background-position:bottom;
      }
      .price{
        text-transform: uppercase;
        font-size: 19px;
        padding-top: 10px;
        line-height: 24px;
        span{
          font-size: 20px;
          font-weight: 400;
        }
        span.sale{
          color:#AA0000;
          font-weight: bold;
          font-size: 30px;
        }
      }
      .info{
        position: absolute;
        top:0px;
        left:0px;
        width:100%;
        height:100%;
        display:none;
        border: 2px solid #000;
        border-radius: 10px;
        .text{
          border-radius: 5px 5px 0px 0px;
          padding: 15px 10px;
          text-transform: uppercase;
          width: 100%;
          height: 129px;
          position: absolute;
          background: rgba(0, 0, 0, 0.8);
          color:#fff;
          overflow: hidden;
        }
      }
    }

    .card-item.foberlet{
      .image{
        background-image: url('/template/images/calc-foberlet.png');
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    .card-item.alk6{
      .image{
        background-image: url('/template/images/calc-6-alkalom.png');
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    .card-item.alk9{
      .image{
        background-image: url('/template/images/calc-9-alkalom.png');
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }

  .card-single:first-child{
    margin-top:0px;
  }

  .card-multi{
    width: 100%;
    margin-top: 25px;
    text-align: center;

    .card-item{
      width: 49%;
      display: inline-block;
      position: relative;
      padding-bottom: 7px;

      .image{
        width: 100%;
        height: 80px;
        box-shadow: 0 4px 2px -2px gray;
        background-position:bottom;
      }
      .price{
        padding-top: 10px;
        text-transform: uppercase;
        font-size: 15px;
        line-height: 22px;
        span{
          font-size: 16px;
          font-weight: 400;
        }
        span.sale{
          color:#AA0000;
          font-weight: bold;
          font-size: 22px;
        }
      }
      .info{
        position: absolute;
        top:0px;
        left:0px;
        width:100%;
        height:100%;
        display:none;
        text-transform: uppercase;
        border: 2px solid #000;
        border-radius: 10px;
        // TODO: 10px should be mobile only desktop should be 12
        font-size: 10px;
        line-height: 12px;;
        color: #fff;

        .text{
          border-radius: 5px 5px 0px 0px;
          width: 100%;
          height: 80px;
          padding: 5px 5px;
          position: absolute;
          background: rgba(0, 0, 0, 0.8);
          overflow: hidden;
        }
      }
    }

    .card-item.kieg1{
      .image{
        background-image: url('/template/images/calc-kartya1.png');
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    .card-item.kieg2{
      .image{
        background-image: url('/template/images/calc-kartya2.png');
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    .card-item.alkalmi1{
      .image{
        background-image: url('/template/images/calc-alkalmidij.png');
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
    .card-item.alkalmi2{
      .image{
        background-image: url('/template/images/calc-alkalmidijzumba.png');
        background-size: contain;
        background-repeat: no-repeat;
      }
    }
  }
}

.price-calculator{
  font-family: @titleFont;
  padding-top: 30px;

  .dance-type{
    margin-top: 10px;

    p {
      text-align: center;

      b{
        font-size: 18px;
        font-weight: 200;
      }
      span.count{
        font-size: 32px;
        font-weight: 400;
      }
    }

    span.min-count{
      font-size: 14px;
      display: inline-block;
      padding-right:15px;
    }
    span.max-count{
      font-size: 14px;
      display: inline-block;
      padding-left:15px;
    }

    .slider.slider-horizontal {
      width: 80%;
    }

    .slider-handle {
      position: absolute;
      top: 0;
      width: 20px;
      height: 20px;
      border:3px solid #fff;
      background: #ffc536;
    }
    .slider-selection {
        background: #ffc536;
      }
  }

  .calculateresult{
    padding-top: 60px;
    .subtitle{
      font-size: 20px;
      outline: none;
    }
    .resulttext{
      font-size: 18px;
    }
    .resultinfo{
      font-size: 14px;
    }
    .resulttitle{
      font-size: 28px;
      font-weight: bold;
    }
    .resultcalculation{
      font-size: 45px;
      color:#ffc536;
    }
  }
}
