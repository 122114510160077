/*
  Social links styles right side floating
*/

.social-link-container {
  position: fixed;
  right: -5px;
  top: 200px;
  width: 50px;
  height: 30px;
  background: transparent;
  z-index: 90;

  .social-item{
    border-radius: 6px;
    background: #fff;
    margin-top:3px;
    font-size: 20px;
    padding: 0px 10px;
    line-height: 30px;
    border: 1px solid #efefef;
    opacity: 1;
    margin-left: 70px;
    transition: all 0.2s;


    a{
      display: block;
      width: 100%;
      height: 100%;
    }

    .facebook{
      color: #3b5998;
    }
    .location{
      color: #ff3636;
    }
    .youtube{
      color: #ff3636;
    }
    .instagram {
      width: 28px;
      height: 30px;
      padding-top: 3px;
      i{
        //color: #666;
        width: 20px;
        height: 20px;
        display: inline-block;
        background-image: url('../images/ico-instagram.png');
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }
    }
  }

  .social-item.loaded {
    margin-left: 0px;
    opacity: 1;
  }

  .social-item:hover{
    background: #efefef;
    margin-left:-5px;
  }

}
