/*
  Application fellepesek styles
*/

.faq-item{   
  
  margin: 15px 0px 0px 0px;;
  
  .title{
    a{
      font-size: 16px;
      color: #666;
      text-decoration: none;
    }

    a:hover{
      font-size: 16px;
      color: #ffc536;
      text-decoration: none;
      font-weight: bold;
    }    

    a.opened{
      font-weight: bold;
      color: #000;
      text-decoration: none;
    }
  }

  .description{
    display: none;
    padding-left: 20px;
    font-size: 13px;
  }
}