/*
  Buttons
*/

.btn.btn-dashed {
  border:1px dashed #a6a6a6;
  background: #fff;
  padding: 3px 10px;
  display: inline-block;
  width: auto;
  border-radius: 10px;
  font-size: 11px;
  text-transform: uppercase;
  color:#333;
  text-decoration: none;
  font-family: @titleFont;
  letter-spacing: 1px;
}

.btn.btn-dashed:hover {
  border:1px dashed #a6a6a6;
  background: @colorYellow;
  color: #fff;
}

.btn.btn-yellow{
  font-family: @titleFont;
  background: @colorYellow;
  display: inline-block;
  width: auto;
  border-radius: 10px;
  text-transform: uppercase;
  color:#fff;
  text-decoration: none;
  letter-spacing: 1px;
}

.btn.btn-yellow-inverz{
  font-family: @titleFont;
  border-color: @colorYellow;
  background-color: #fff;
  display: inline-block;
  width: auto;
  border-radius: 10px;
  text-transform: uppercase;
  color:@colorYellow;
  text-decoration: none;
  letter-spacing: 1px;
}

.btn.btn-fullrow{
  width:100%;
}

.btn.btn-allevent{
  padding-top: 10px;
  text-decoration: none;
  color:@colorBase;
  letter-spacing: 1px;
  border-radius: 10px;
  text-transform: uppercase;
  display: inline-block;
  font-family: @titleFont;
  background: rgb(227,50,78);
  background: linear-gradient(90deg, rgba(227,50,78,1) 0%, rgba(183,39,39,1) 60%);
  font-size: 110%;
  transition: all 1s;

  i {
    font-size: 125%;
    font-weight: bold;
  }
}
// .btn.btn-allevent:hover{
//   background: rgb(183,39,39);
//   background: linear-gradient(90deg, rgba(183,39,39,1) 0%, rgba(227,50,78,1) 54%);
// }

.btn-to-top{
  position: fixed;
  display: none;
  bottom: 50px;
  right: 20px;
  font-size: 50px;
  color: #888;
  cursor: pointer;
}

.btn.disabled, .btn[disabled] {
  background: #ddd !important;
  border-color: #666 !important;
  color: #666 !important;

  i {
    display: inline-block !important;
    font-size: 19px;
  }
}
input:disabled {
  background: #ddd;
  border-color: #ccc;
}

// Dancetype colored buttons
.btn.btn-yellow.btn-zumba{
  background: @zumbaColor;
}
.btn.btn-yellow-inverz.btn-zumba{
  border-color: @zumbaColor;
  color:@zumbaColor;
}
.btn.btn-yellow.btn-tarsas{
  background: @tarsasColor;
}
.btn.btn-yellow-inverz.btn-tarsas{
  border-color: @tarsasColor;
  color:@tarsasColor;
}
.btn.btn-yellow.btn-salsa{
  background: @salsaColor;
}
.btn.btn-yellow-inverz.btn-salsa{
  border-color: @salsaColor;
  color:@salsaColor;
}
.btn.btn-yellow.btn-westcoast{
  background: @swingColor;
}
.btn.btn-yellow-inverz.btn-westcoast{
  border-color: @swingColor;
  color:@swingColor;
}
.btn.btn-yellow.btn-bachata{
  background: @bachataColor;
}
.btn.btn-yellow-inverz.btn-bachata{
  border-color: @bachataColor;
  color:@bachataColor;
}
.btn.btn-yellow.btn-rocky{
  background: @rockyColor;
}
.btn.btn-yellow-inverz.btn-rocky{
  border-color: @rockyColor;
  color:@rockyColor;
}
.btn.btn-yellow.btn-versenyzo{
  background: @versenyzoColor;
}
.btn.btn-yellow-inverz.btn-versenyzo{
  border-color: @versenyzoColor;
  color:@versenyzoColor;
}