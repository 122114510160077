/*
  Startpage highlighted services
*/
.highlight-banner{
  height:310px; width:100%; overflow:hidden;
  text-align: center;
  background-size: cover;
  background-position: center center;

  a{
    width:100%;
    height: 100%;
    display: block;
  }
}