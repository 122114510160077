/*
  Footer style
*/

footer{
  background-color: #393939;
  min-height: 300px;
  margin-bottom: 60px;
  padding: 10px 0px 60px 0px;
  font-family: @contentFont;
  color:#fff;
  position: relative;


  .bottomborder {
    border-bottom:1px dashed #666;
  }
  .h4{
    font-family: @contentFont;
    display: block;
    margin-bottom: 10px;
    padding:0px;
  }
  .h5{
    font-family: @contentFont;
    display: block;
    margin-top: 30px;
    padding:0px;
  }
  a{
    color: #fff;
    text-decoration: underline;
  }
  a:hover {
    color: @colorYellow;
  }

  nav{
    width: 100%;

    a{
      display: inline-block;
      color: #fff;
      font-size: 16px;
      font-weight: 200;
      margin-right:30px;
      text-decoration: none;
      text-transform: uppercase;
      font-family: @contentFont;
      margin-top:5px;
    }

    a:hover{
      text-decoration: none;
      color: @colorYellow;
    }

  }
  .footerlogo {
    line-height: 60px;
  }
  .goldance-logo{
    line-height: normal;
    vertical-align: middle;
    display: inline-block;
    width: 100%;
    max-width: 158px;
    height: 40px;
    background-image: url('/template/images/logo-footer.png');
    background-repeat:  no-repeat;
    background-size: contain;
    a{
      width:100%;
      height: 100%;
      display: block;
    }
  }
  .gdtancsport-logo{
    line-height: normal;
    vertical-align: middle;
    display: inline-block;
    width: 100%;
    max-width: 80px;
    height: 60px;
    margin-top: 5px;
    margin-left:30px;
    background-image: url('/template/images/gd-tancsport-logo.png');
    background-repeat:  no-repeat;
    background-size: contain;
    a{
      width:100%;
      height: 100%;
      display: block;
    }
  }

  .icons{
    a{
      font-size: 50px;
      color: #fefefe;
      margin-right:40px;
    }

    a:hover{
      color:#ccc;
    }
  }
}

footer:before{
  width:100%;
  height: 4px;
  display: block;
  content: "";
  background: url('/template/images/footer-top.png');
  background-repeat: repeat-x;
  position: absolute;
  top:-4px;
  left:0px;
}

footer:after{
  width:100%;
  height: 4px;
  display: block;
  content: "";
  background: url('/template/images/footer-bottom.png');
  background-repeat: repeat-x;
  position: absolute;
  bottom:-4px;
  left:0px;
}