/*
  Application dancepages styles
*/

.dancepage-hero{
  width:100%;
  height: 300px;
  position: relative;
  background-color: #000;
  background-position: center;

  .top-shade{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='100,0 0,100 0, 0' style='fill:#f2f2f2' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    display: block;
    height: 100px;
    position: absolute;
    width: 100%;
    left: 0;
    top:0px;;
  }

  .bottom-shade{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,100 100,0 100,100' style='fill:#f2f2f2' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    display: block;
    height: 100px;
    position: absolute;
    width: 100%;
    left: 0;
    bottom:0px;;
  }

  .highlight{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+47&1+0,0+46 */
    background: -moz-radial-gradient(center, ellipse cover, rgba(255,255,255,0.5) 0%, rgba(255,255,255,0) 70%, rgba(255,255,255,0) 07%); /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 70%,rgba(255,255,255,0) 07%); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center, rgba(255,255,255,0.5) 0%,rgba(255,255,255,0) 70%,rgba(255,255,255,0) 07%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
      width:100%;
      height:385px;
      display: block;
      z-index:15;
  }

}

.dancepage.swing .dancepage-hero, .dancepage.west-coast-swing .dancepage-hero{
  background: none;
  background-image: url('/images/subpagehero-title-swing.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.dancepage.eskuvoi .dancepage-hero{
  background: none;
  background-image: url('/images/subpagehero-title-eskuvo.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.dancepage.tarsastanc .dancepage-hero{
  background: none;
  background-image: url('/images/subpagehero-title-tarsas.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.dancepage {
  h4 {
    margin:0px;
    padding: 20px 0px 10px;
  }
  .timetable-item-border{
    border-bottom: 1px dashed #ddd;
    padding: 10px;
  }
  .locationsMap{
    height: auto;
  }
}

.dancepage.eskuvoi{
  .dance-item{
    padding: 20px;
    color: #5e5e5e;

    .image{
      background-size: contain;
      width: 100%;
      height: 135px;
      background-position: center;
      position: relative;

      .btn_play{
        position: absolute;
        width: 100%;
        height: 100%;
        color: #000;
        opacity: 0;
        font-size: 80px;
        text-align: center;
        padding-top: 30px;
        -webkit-transition: all 1s; /* Safari */
        transition: all 1s;
      }
    }
    .image:hover{
      .btn_play{
        opacity: 0.8;
      }
    }
    .title{
      font-size: 20px;
      font-family: @titleFont;
      text-align: center;
      width: 100%;
    }
    .descr{
      font-size: 14px;
      text-align: center;
      width: 100%;
    }
  }
}

.dancepage.szalagavato{
  .dance-item{
    padding: 20px;
    color: #5e5e5e;
    height: 320px;

    .image{
      background-size: contain;
      width: 100%;
      height: 200px;
      background-position: center;
      background-repeat: no-repeat;
      position: relative;

      .btn_play{
        position: absolute;
        width: 100%;
        height: 100%;
        color: #fff;
        opacity: 0;
        font-size: 80px;
        text-align: center;
        padding-top: 40px;
        -webkit-transition: all 1s; /* Safari */
        transition: all 1s;
      }
    }
    .image:hover{
      .btn_play{
        opacity: 0.8;
      }
    }
    .title{
      font-size: 20px;
      font-family: @titleFont;
      text-align: center;
      width: 100%;
    }
    .descr{
      font-size: 14px;
      text-align: center;
      width: 100%;
    }
  }
}


