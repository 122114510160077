/*
 Content styles
*/

.content{
  width: 100%;
  padding: 20px 0px;

  font-family: @contentFont;
  color:#494949;

  .assetbox{
    width: 100%;
    // border: 1px solid #a2a2a2;
    text-align: center;

    img{
      max-width: 100%;
      height: auto;
    }

    .title{
      padding: 10px;
      font-size: 12px;
      color: #a2a2a2;

    }

  }

  p img{
    margin: 10px;
    max-width: 100%;
    height: auto;
  }

  h1, h2, h3, h4, h5, h6 {
    img{
      margin: 10px;
    }
  }

  .blocktitle{
    font-size: 28px;
    font-family: @titleFont;
    font-weight: 100;
  }

  .more-btn-with-sepa, .more-btn-with-sepa-close{
    width: 100%;
    position:relative;
    text-align: center;
    margin: 20px 0px;

    .separator{
      border: 0px;
      margin:0px;
      width:100%;
      border-bottom: 1px dashed #a6a6a6;
      height: 2px;
      display: inline-block;
    }

    .btn-dashed{
      display: inline-block;
      margin-top: -30px;

    }
  }

  .more-content {
    display:none;
  }
}

/* Helper classes */

.content.grey{
  background: #F2F2F2;
}

.content.white{
  background: #fff;
}

