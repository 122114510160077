/*
  Application fellepesek styles
*/

.fellepes-item{   
  
  padding: 15px;
  
  a{
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;     
    height: 200px;
  }
}