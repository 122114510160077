/*
  Application orarend styles
    - form and discount styles:
      @import 'form-elements';
      @import 'discount-ul';
*/

// Home page
.events-mobile{
  display: none;
}

.events, .right-hover-column-body .banners{
    width:100%;
    min-height: 270px;
    margin-top: 30px;
    .banner-item {
      margin-bottom:10px;
      width:100%;
      text-align: center;
      img {
         max-width: 227px;
        // max-width: 215px;
      }
    }
    .banner-item:first-child {
      margin-top:0px;
    }
  }

.events-mobile, .right-hover-column-body{
  .banners{
    width:100%;
    min-height: 270px;
    margin-top: 20px;
    .banner-item {
      margin-bottom:10px;
      width:100%;
      text-align: center;
      img {
        // max-width: 227px;
        // max-width: 215px;
      }
    }
    .banner-item:first-child {
      margin-top:0px;
    }
  }

  .events{
    width:100%;
    .title{
      font-family: @titleFont;
      width:100%;
      font-size: 19px;
      color:#fff;
      background:#fec436;
      padding: 5px 5px;
    }

    .event-item{
      width:100%;
      margin-top:10px;
      padding-bottom:10px;
      border-bottom: 1px dashed #878787;

      .description{
        width:82%;
        display:inline-block;
        position: relative;

        a.event-link {
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
        }

        .datetime,{
          font-size: 14px;
          color: #000;
          font-family: @titleFont;

          span{
            color: #878787;
          }

        }
        .city,{
          font-weight: bold;
          font-size: 12px;
        }
        .address,{
          font-size: 12px;
        }
      }
      .icons{
        width:15%;
        display:inline-block;
        text-align: center;
        font-size: 20px;

        .fb{
          margin-left:20px;
          a{
            color:#3040a2;
          }
        }
        .other{
          margin-left:17px;
          a{
            color:#3040a2;
          }
        }
        .map{
          margin-left:20px;
          a{
            color:#fec436;
          }
        }
      }
    }
    .event-item:last-child{
      border-bottom: 0px dashed #878787;
    }

  }
}

// Events page
.events-mobile, .goldance-events{

  padding: 15px;

  .event-item{
    margin-top:10px;
    padding-bottom:10px;
    border-bottom: 1px dashed #878787;

    .info{
      width:80%;
      display:inline-block;

      .datetime{
        font-size: 18px;
        color: #000;
        font-family: @titleFont;

        span{
          color: #878787;
        }

      }
      .city{
        font-weight: bold;
        font-size: 20px;
      }
      .address{
        font-size: 14px;
      }
    }
    .icons{
      width:19%;
      display:inline-block;
      text-align: center;

      .fb{
        display: inline-block;
        font-size: 9px;

        a{
          color:#3040a2;
          font-size: 30px;
        }
      }
      .other{
        display: inline-block;
        font-size: 9px;

        a{
          color:#3040a2;
          font-size: 30px;
        }
      }
      .map{
        display: inline-block;
        margin-left:20px;
        font-size: 9px;

        a{
          color: @colorYellow;
          font-size: 30px;
        }
      }
    }
    .description{
      position:relative;
      h4 {
        padding-bottom: 0px;
      }
      a.event-link {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        z-index: 100;
      }
      .event-img{
        height: 150px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url('/template/images/img-esemeny.png');
      }
    }
  }
}