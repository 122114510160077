/*
  Application locations styles
*/
.locationblock{
  .error{
    font-size: 11px;
    color: #aa0000;
  }
}

.goldance-location{
  .picture{
    // padding-top: 30px;
    text-align:center;
    img{
      width:100%;
    }
    .fa{
      font-size: 100px;
      opacity: 0.3;
    }
  }
}

.location-list{

  li{
    margin-top: 0px;
    padding: 5px;
    transition: all 0.4s;

    i{
      color: #ff0000;
    }
    span{
      font-size: 11px;
    }
    i.fa-chevron-down, i.fa-chevron-up{
      cursor: pointer;
    }
    .list{
      line-height: 14px;
    }
    .description{
      //display: none;
      opacity: 0;
      height: 0px;
      padding-top:10px;
      font-size: 10px;
      transition: all 0.3s;
      user-select: none;


      .img{
        font-size: 40px;

        i{
          opacity: 0.2;
        }
      }
    }
    .btn{
      padding: 5px 20px;
    }
  }

  li.opened{
    background: #fff;

    .description{
      display: block;
      opacity: 1;
      height: auto;
    }
  }

}

.locationsMap{
  position: relative;
  height: 450px;

  #locationsMapLoader{
    top:0px;
    left:0px;
    position:absolute;
    display: block;
    width:100%;
    height: 450px;
    z-index: 1000;
    text-align:center;
    padding-top: 100px;
    font-size: 40px;
  }

  #locationsMap{
    height:450px;
    width:100%;
    visibility:hidden;
  }
}

// Location page
.location-item{
  padding: 5px;

  .inner{
    position: relative;
    margin:10px;
    height: 300px;
    width: 100%;
    background-image:url('../images/location-pic-holder.png');
    background-position: center center;
    background-size: cover;
    padding:0px;

    .description{
      display:none;
      width: 350px;
      height: 300px;
      border: 2px solid #e2e2e2;
      font-size: 14px;
      line-height: 16px;

      font-family: @contentFont;
      font-size: 14px;
      margin-top:15px;
      font-weight: 100;
    }

    .data{

      display: none;
      padding: 10px;
      height: 300px;
      width: 100%;
      background-color: rgba(230, 230, 230, 0.9);


      .info {
        overflow-y: scroll;
        height: 230px;

        h4{
          width: 100%;
          font-size: 14px;
          color: #494949;
          font-family: @titleFont;
        }

        ul.location-details{
          width: 100%;
          // height: 165px;    uncomment after final functions present
          height: 200px;
          padding-left:0px;
          margin-left:0px;


          .label{
            font-size: 12px;
            color:#494949;
            font-family: @titleFont;
            display:inline-block;
            width: 38%;
            text-align: left;
            vertical-align: top;
          }
          .value{
            font-size: 12px;
            color:#494949;
            font-weight: 300;
            font-family: @titleFont;
            display:inline-block;
            width: 60%;
            text-align: left;
            vertical-align: top;
            p{
              padding: 0px 0px;
              margin: 0px 0px 2px 0px;
            }
          }
        }
      }

      .more {
        padding-top: 10px;
        height: 30px;
      }
    }

  }
}

.location-item.bottomborder {
    border-bottom:1px dashed #666;
}