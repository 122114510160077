/*
  Application subpage hero navigation
*/

.subhero {
  width:100%;
  height: 175px;
  position: relative;  
  background-position: center center;
  background-size: cover;
  background-color: #f2f2f2;
  border-bottom:10px solid #e9e9e9;

  .top-shade{
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='100,0 0,100 0, 0' style='fill:#f2f2f2' /%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: '';
    display: block;
    height: 60px;
    position: absolute;
    width: 100%;
    left: 0;
    top:0px;;   
  }

  nav{
    width:100%;
    height: 40px;
    position:absolute;
    bottom:-5px;
    left:0px;
    text-align: center;
    z-index: 25;

    ul{
      width:100%;
      display:inline-block;
      list-style-type: none;

      li{
        width:auto;      
        height:45px;
        display:inline-block;
        position: relative;
        a{        
          border-top:1px solid #F2F2F2;
          border-left:1px solid #F2F2F2;
          border-right:1px solid #F2F2F2;
          padding: 10px 20px 5px 20px;
          color:#000;
          font-size: 17px;
          font-family: @titleFont;
          font-weight: 300;
          text-decoration: none;
          border-top-left-radius: 7px;
          border-top-right-radius: 7px;
          background:#F2F2F2;
          display:block;
          width:100%;
          height: 100%;
          cursor: pointer;
          z-index: 40;

          i{
            color:#ccc;
            margin-right:5px;
            font-size: 19px;
          }
        }
        a:hover{
          background:#ffc536;
           border-color: #ffc536;;
          color:#fff;
          i{
            color:#fff;
          }
        }      
      }
    }
  }
}
  