/*
  Goldance responsive media queries
*/


@media all and (min-width: 1000px) {
  iframe{
    min-height: 300px;
  }
  .hero {
    display:block;
  }
  .hero-mobile {
    display:none;
  }
  .mobile {
    // display: none !important;
    display: inline-block !important;
    visibility: hidden;
    height: 0px;
    width:0px;
  }
  .nomobile{
    display:block !important;
  }
}

@media all and (max-width: 992px) and (min-width: 768px) {
  iframe{
    min-height: 300px;
  }
  .right-hover-column{
    display: none;
  }
  .events-mobile{
    display: block;
  }

  .site-top-container{
    padding-top: 130px;
  }

  .start-here{
    img{
      width:100%;
      height:auto;
    }
  }
  .hero {
    display:none;
  }
  .hero-mobile {
    display:block;
  }
  .nomobile{
    display:block !important;
  }
  .mobile {
    // display: none !important;
    display: inline-block !important;
    visibility: hidden;
    height: 0px;
    width:0px;
  }

}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  iframe{
    min-height: 300px;
  }
  // Startpage
  .right-hover-column{
    display: none;
  }
  .events-mobile{
    display: block;
  }
  .site-top-container{
    padding-top: 130px;

  }
  .start-here{
    margin-top:0px;
    text-align: center;
    height: auto;
    padding: 20px 0px;
    img{
      width:100%;
      height:auto;
    }
  }
  .highlight-services{
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .highlight-banner{
    height: 150px;
  }

  .hero {
    display:none;
  }
  .hero-mobile {
    display:block;

    .carousel-inner>.item>a{
      display: block;
      text-align: center;
    }

    .carousel-inner>.item>a>img, .carousel-inner>.item>img{
      height: 500px;
      z-index: 150;
      display: inline-block;
    }
    .carousel-control.right {
      background-image: none;
    }
    .carousel-control.left {
      background-image: none;
    }
    .glyphicon-chevron-right:before{
      background: #aaa;
    }
    .glyphicon-chevron-left:before{
      background: #aaa;
    }
    .carousel-caption {
      font-size: 20px;
    }
    .carousel-indicators li{
      width: 20px;
      height: 20px;
      border: 3px solid #fff;
    }

  }
  .btn-yellow{
    font-size: 14px;
  }
  footer{
    nav {
      text-align: center;
      margin-bottom: 20px;
    }
    .goldance-logo {
      background-position: center;
      max-width: 100%;
    }
  }
  .site-top .popup-navigation .nav-head .btn-cont {
    width:50%;
  }

  // Content
  .subhero nav {
    display:none;
  }
  .popup-navigation{
    height: 310px;
    overflow: hidden;

    .nav-body{
      max-height: 180px;
      overflow-y: scroll;
    }
  }


  .nomobile{
    display:none !important;
  }
  .mobile {
    // display: none !important;
    display: inline-block !important;
    visibility: hidden;
    height: 0px;
    width:0px;
  }

}

@media only screen and (max-width : 768px) and (orientation:landscape) {
  iframe{
    min-height: 300px;
  }
  .site-top.scrolled{
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .events-mobile{
    display: block;

  }
  .site-top-container{
    padding-top: 130px;
  }
  .site-top .logo-container {
    height: 40px;
    .logo img{
      height: 35px;
    }
    .info{
      padding-top:5px;
      height: 40px;
    }
  }
  .popup-navigation{
    height: 310px;
    overflow: hidden;

    .nav-body{
      max-height: 180px;
      overflow-y: scroll;
    }
  }

  .service-item{
    text-align: center;
  }

  .nomobile{
    display:none !important;
  }
  .mobile {
    display: block !important;
  }

  .nomobile{
    display:none !important;
  }
  .mobile {
    display: inline-block !important;
    visibility: visible;
    height: auto;
    width:auto;
  }
  .firstlesson-head-mobile{
    display: block !important;
  }

}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
/* Force table to not be like tables anymore */
table, thead, tbody, th, td, tr {
  display: block;
}

/* Hide table headers (but not display: none;, for accessibility) */
thead tr {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

tr {
  margin: 0 0 1rem 0;
  border-top:2px solid #aaa;
}
tr.child {
  margin: 0 0 1rem 0;
  border-top:0px
}

tr:nth-child(odd) {
  background: #ccc;
}

td {
  /* Behave  like a "row" */
  border: none;
  border-bottom: 1px solid #eee;
  position: relative;
  padding-left: 50%;
}

td:before {
  /* Now like a table header */
  position: absolute;
  /* Top/left values mimic padding */
  top: 0;
  left: 6px;
  width: 45%;
  padding-right: 10px;
  white-space: nowrap;
}

iframe{
  min-height: auto;
}

// Timetable mobile
.mobile-col-head {
  display: inline;
  font-weight: bold;
}

  .events-mobile{
    display: block;

    .banner-item img {
      // max-width: 100% !important;
      max-width: 100%;
      width:100%;
    }
  }
  .popup-nav-container {
    .popup-navigation {
      margin-top: -110px;
      height:630px;
      .nav-head {
        .btn-cont {
          .facebook, .map {
            display:none;
          }
          a {
            font-size: 32px;
          }
        }
      }
      .nav-body {
        max-height: 80% !important;
      }
    }
  }

  .site-top-container{
    padding-top: 130px;
    .logo-container{
      .info{
        display: none;
      }
    }


    .covid19{
      text-align: right;
      padding: 5px 10px 0px 10px;
      img{
        max-height: 15px;
      }
    }

  }
  .site-top .logo-container {
    height: 40px;
    .logo img{
      height: 35px;
    }
  }

  .social-link-container{
    display: none;
  }

  .popupnav-social-container{
    display: block !important;
  }

  .popup-nav-container .popup-navigation .nav-head .btn-cont {
    width: 50%;
  }

  .nav-body{
    max-height: 500px;
    overflow-y: scroll;

    .mcollapse {
      .title {
        position: relative;


        .fa{
          position: absolute;
          right: 20px;
        }

      }
      ul{
        display: none;
      }
    }
  }

  .hero-mobile .carousel-inner>.item>a{
    display: block;
    text-align: center;
  }

  .hero-mobile .carousel-inner > .item > a > img, .hero-mobile .carousel-inner > .item > img{
    height: 350px;
    z-index: 150;
    display: inline-block;
  }

  // Dancepage nav
  .dancepage {
    .dancepage-nav {
      height: auto;

      nav {
        position: relative;

        ul {
          padding: 0px;
          margin: 0px;
          li {
            display: block;
            width:100%;
            a {
              width:100%;
            }
          }
        }
      }
    }

    .main-image{
      top: -380px;
      max-width: 95%;
    }
  }

  iframe {
    width: 90%;
  }

  .content{
    img {
      max-width: 100%;
      height: auto;
    }
  }

  .goldance-events{
    .event-item{
      .icons{
        width: 95%;
        span{
          display: none;
        }
      }
    }
  }

  .service-item{
    img {
      width: 100%;
    }
  }
  .service-item + .service-item{
      margin-top:20px;
  }

  .nomobile{
    display:none !important;
  }
  .mobile {
    display: inline-block !important;
    visibility: visible;
    height: auto;
    width:auto;
  }
  .firstlesson-head-mobile{
    display: block !important;
  }
}

/* Custom, iPhone Retina */
@media only screen and (max-width : 321px) {
  iframe{
    min-height: auto;
  }
  .events-mobile{
    display: block;
    .banner-item img {
      max-width: 100% !important;
      width:100%;
    }
  }

  .hero-mobile .carousel-inner>.item>a{
    display: block;
    text-align: center;
  }

  .hero-mobile .carousel-inner > .item > a > img, .hero-mobile .carousel-inner > .item > img{
    height: 300px;
    z-index: 150;
    display: inline-block;
  }

  .nomobile{
    display:none !important;
  }
  .mobile {
    display: inline-block !important;
    visibility: visible;
    height: auto;
    width:auto;
  }
  .firstlesson-head-mobile{
    display: block !important;
  }
  table.orarend td .btn-yellow {
    font-size: 8px;
    padding: 5px 5px;
  }

}

@media only screen and (max-width : 321px) and (orientation:landscape) {
  .events-mobile{
    display: block;
    .banner-item img {
      max-width: 100% !important;
      width:100%;
    }
  }
  .highlight-services{
    .service-item + .service-item{
      margin-top:20px;
    }
  }

  .hero-mobile .carousel-inner>.item>a{
    display: block;
    text-align: center;
  }

  .hero-mobile .carousel-inner > .item > a > img, .hero-mobile .carousel-inner > .item > img{
    height: 300px;
    z-index: 150;
    display: inline-block;
  }

  .nomobile{
    display:none !important;
  }
  .mobile {
    display: inline-block !important;
    visibility: visible;
    height: auto;
    width:auto;
  }

  table.orarend td .btn-yellow {
    font-size: 8px;
    padding: 5px 5px;
  }

}
