/*
  Application form elements style
*/

// Styled select elements
.selectric-wrapper{
  margin-top:10px;

  .selectric{
    background:fff;

    .label{
      height: 30px;
      font-size: 16px;
      color:#999;
      font-family: @titleFont;
      font-weight: 100;
      text-align: left;
      line-height: 25px;
    }

    .button{
      background: #f1ba33;
      margin:1px;
      height: 28px;
      line-height: 28px;
      font-size: 24px;
      color:#fff;
    }
    .button:after{
      content: none;
    }
  }

}

.selectric-wrapper.selectric-disabled{
  .button{
    background: #333;
    margin:1px;
    height: 28px;
    line-height: 28px;
    font-size: 24px;
    color:#fff;
  }
}

input.timepicker{
  width: 160px;
  height: 30px;
  border: 1px solid #ddd;
  background: #f8f8f8;

}