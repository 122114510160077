/*
  Application start page right hover style
*/

.right-hover-column{
  position: absolute;
  right: 40px;
  top: 530px;
  width: 260px;
  z-index: 30;
}

.right-hover-column-head{
  width: 100%;
  height: auto;
  margin-top: -10px;
  min-height: 55px;
  background: url('../images/banner-right-top.png');
  background-repeat: no-repeat;
  transform: rotate(-7deg);
}
.right-hover-column-body{
  background: #FCFCFC;
  width: 249px;
  margin: -37px 0px 0px 6px;
  padding: 20px 10px 10px 10px;
  min-height: 300px;
  -webkit-box-shadow: 0px 5px 23px -5px rgba(0,0,0,0.57);
  -moz-box-shadow: 0px 5px 23px -5px rgba(0,0,0,0.57);
  box-shadow: 0px 5px 23px -5px rgba(0,0,0,0.57);
}

