/*
  Application - new lesson reg application
*/

#newlessonRegModal{
  .modal-dialog{
    margin-top: 210px;
    .modal-content{
      position: relative;

      .modal-topdecor{
        width: 100%;
        height: 180px;
        top: -180px;
        left: 0px;
        position: absolute;
        background-image: url('../images/ingyenes-ora/modal-header.png');
        background-size: contain;
        background-position:bottom center;
        background-repeat: no-repeat;
      }
      .modal-body{
        background: #f6f6f6;
        // padding-bottom: 50px;
        .selectric-wrapper .selectric {
          height:45px;
          background-color: #fff;
          background-image: none;
          border: 1px solid #ccc;
          border-radius: 4px;

          .label{
            height:45px;
            line-height:45px;
            font-family: @titleFont;
          }
          .button{
            height:43px;
            line-height: 43px;
          }
        }
      }
      .modal-footer{
        text-align: left;
      }
      .modal-footer, .modal-body{
        .form-group{
          input[type="text"] {
            height: 45px;
            font-family: @titleFont;

          }
          .col-sm-4 {
            padding-right: 5px;
            padding-left: 5px;
          }
        }
      }
    }
  }
}

#newLessonApp{
  position: relative;

  .newlessonreg-load-placeholder{
    position: absolute;
    top:0px;
    left: 0px;
    width: 100%;
    height: 100%;
    padding-top: 150px;
    text-align: center;
    font-size: 60px;
    color: #ddd;
    background: #eee;
  }


}

/*
  Media queries
*/


@media all and (min-width: 1000px) {

}

@media all and (max-width: 992px) and (min-width: 768px) {

}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {

}

@media only screen and (max-width : 768px) and (orientation:landscape) {

}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {
  #newlessonRegModal .modal-dialog {
    margin-top: 160px;
  }
  #newlessonRegModal .form-group input {
    text-align: left !important;
  }
  #newlessonRegModal .btn.btn-yellow, #newlessonRegModal .btn.btn-yellow-inverz{
    width: 100%;
    margin: 0px;
    margin-top: 10px;
  }

}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {
  #newlessonRegModal .modal-dialog {
    margin-top: 160px;
  }
  #newlessonRegModal .form-group input {
    text-align: left !important;
  }
}
