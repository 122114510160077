/*
  Application orarend styles
    - form and discount styles:
      @import 'form-elements';
      @import 'discount-ul';
*/

.orarend-block{
  position:relative;
  height: 500px;
  .table-responsive{
    overflow-x: visible !important;
  }

}
.orarend-block.table-responsive{
  overflow-x: visible !important;
  overflow-y: scroll !important;
}

.timetable-placeholder{
  position: absolute;
  top:0px;
  left: 0px;
  width: 100%;
  height: 400px;
  padding-top: 150px;
  text-align: center;
  font-size: 60px;
  color: #ddd;
}

table.orarend{
  width:100%;
  font-size: 12px;
  opacity: 0;
  transition: all 0.3s;

  tbody{
    width:100%;
  }
  th{
    background: #ffedc2;
    text-transform: uppercase;
    font-family: @titleFont;
    border-bottom: 1px solid #ddd !important;
  }

  .data-row td{
    border-bottom: 1px dashed #ddd !important;
    border-top:0px !important;
    padding: 4px;

    .comment{
      font-size: 10px;
      font-weight: bold;
      color:#999
    }
  }

  tr {
    position:relative;
  }

  .hidden-row{
    opacity: 0.2;
    position:relative;

   .btn-yellow{
      font-size: 10px;
      padding: 5px 5px;
      background: #ccc;
    }
  }

  .selected-row{
    opacity: 1;
    border: 1px solid @colorYellow !important;

    .form-control {
      width: 100%;
      height: 25px;
      font-size: 12px;
      margin-top: 3px;
    }

    .timetable-reg-cancel{
      margin-top:5px;
    }

    .error{
      border: 1px solid #ff0000;
    }
    .valid{
      border: 1px solid #999;
    }
  }

  td .btn-yellow{
    font-size: 10px;
    padding: 5px 5px;
  }
  .btn-yellow.sent{
    font-size: 10px;
    padding: 5px 20px;
    background: #ccc;
  }

}
table.orarend.loaded{
  opacity: 1;
  height:auto;;
}

.orarendKerulet{
  span.select-label{
    float: left;
  }
  .selectric-wrapper{
    display: inline-block;
    .selectric{}
  }
}
span.select-label{
  display: block;
  float: none;
  padding-right: 8px;
  padding-top: 15px;
  font-family: @titleFont;
  font-size: 18px;
  color: #999;
}

/* Dancetype selector page - used in firstlessonreg and newlessonreg too*/
.dancetype-selector{
  text-align: center;

  .dancetype{
    text-align: center;
    width: 180px;
    display: inline-block;
    .image{
      width: 100%;
      height: 200px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: bottom;
      cursor: pointer;

      a{
        width:100%;
        height:100%;
        display:block;
      }
    }
    .btn-yellow{
      color: #fff;
    }
  }

  /* dancetype box types */
  .dancetype.zumba{
    .image{
      background-image: url('../images/dance-zumba.png');
    }
    .btn-yellow{
      background-color: @zumbaColor;
    }
  }
  .dancetype.tarsas{
    .image{
      background-image: url('../images/dance-tarsas.png');
    }
    .btn-yellow{
      background-color: @tarsasColor;
    }
  }
  .dancetype.tarsastanc{
    .image{
      background-image: url('../images/dance-tarsas.png');
    }
    .btn-yellow{
      background-color: @tarsasColor;
    }
  }
  .dancetype.salsa{
    .image{
      background-image: url('../images/dance-salsa.png');
    }
    .btn-yellow{
      background-color: @salsaColor;
    }
  }
  .dancetype.westcoast{
    .image{
      background-image: url('../images/dance-westcoast.png');
    }
    .btn-yellow{
      background-color: @swingColor;
    }
  }
  .dancetype.swing, .dancetype.west-coast-swing{
    .image{
      background-image: url('../images/dance-westcoast.png');
    }
    .btn-yellow{
      background-color: @swingColor;
    }
  }
  .dancetype.west-coast-swing{
    .image{
      background-image: url('../images/dance-westcoast.png');
    }
    .btn-yellow{
      background-color: @swingColor;
    }
  }
  .dancetype.bachata{
    .image{
      background-image: url('../images/dance-batchata.png');
    }
    .btn-yellow{
      background-color: @bachataColor;
    }
  }
  .dancetype.rocky{
    .image{
      background-image: url('../images/dance-rocky.png');
    }
    .btn-yellow{
      background-color: @rockyColor;
    }
  }
  .dancetype.versenyzo{
    .image{
      background-image: url('../images/dance-versenyzo.png');
    }
    .btn-yellow{
      background-color: @versenyzoColor;
    }
  }
}

// modifications for timetable
.timetable.dancetype-selector{
  .dancetype{
    width: 123px;
    .image{
      height: 120px;
    }
    .btn-yellow{
      font-size: 12px;
      width:100%;
    }
  }
}

.timetable-content-panels{
  .pricebox{
    border-left: 1px solid #999;
  }

  .sale{
    color:#aa0000;
    font-weight: bold;
  }
  h4.sale{
    text-align: center;
  }
  strike{
    // font-size: 12px;;
  }
}
