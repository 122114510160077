/*
  Application hero navigation
*/

.hero nav{
  width:100%;
  height: 45px;
  position:absolute;
  // --  CS solution for the shade
  // bottom:43px;
  // --  IMG solution for the shade
  bottom: 124px;
  left:0px;
  text-align: center;
  z-index: 25;
  transform: rotate(-4.9deg);

  ul{
    width:100%;
    display:inline-block;
    list-style-type: none;

    li{
      width:auto;      
      height:45px;
      display:inline-block;
      position: relative;
  

      a{        
        border-top:1px solid #F2F2F2;
        border-left:1px solid #F2F2F2;
        border-right:1px solid #F2F2F2;
        padding: 8px 20px 5px 20px;
        color:#000;
        font-size: 17px;
        font-family: @titleFont;
        font-weight: 300;
        text-decoration: none;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        background:#F2F2F2;
        display:block;
        width:100%;
        height: 100%;
        cursor: pointer;
        z-index: 40;

        i{
          color:#ccc;
          margin-right:5px;
          font-size: 19px;
        }
      }

      a:hover{    
        // color: #000;
        i{
          color: @colorYellow;
        }
      }      
    }


  }
}