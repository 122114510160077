/*
  Application fellepesek styles
*/

.gallery-cat{   
  .gallery-cat-item{
    width: 100%;
    height: 100%;
    display: block;
    padding: 10px;

    a{
      font-family: @titleFont;
      width:100%;
      height: 100%;
      display: block;
      text-decoration: none;   
      text-align: center;   

      figure{
        width:100%;
        height:150px;
        background-size: cover;
        background-position: center center;
        border-radius: 10px;
        margin: 10px 0px;
      }
      small{
        font-size: 12px;
        color:#aaa;     
        display: block;   
      }

      span.title{
        font-size: 18px;
        color: #333;
        font-weight: bold;
        display: block; 
      }

      span.updated{
        color:#999;
        font-size: 14px;
        display: block; 
      }

    }

    a:hover{
      text-decoration: none;

      figure{
        -webkit-box-shadow: inset 0px 0px 34px 10px rgba(0,0,0,0.6);
        -moz-box-shadow: inset 0px 0px 34px 10px rgba(0,0,0,0.6);
        box-shadow: inset 0px 0px 34px 10px rgba(0,0,0,0.6);
      }
    }
  }
}

.gallery-subcat{   
  .gallery-subcat-item{
    width: 100%;
    height: 100%;
    display: block;
    padding: 10px;

    a{
      font-family: @titleFont;
      width:100%;
      height: 100%;
      display: block;
      text-decoration: none;   
      text-align: center;   

      figure{
        width:100%;
        height:150px;
        background-size: cover;
        background-position: center center;
        border-radius: 10px;
        margin: 10px 0px;
      }

      span.title{
        font-size: 18px;
        color: #333;
        font-weight: bold;
        display: block; 
      }

      span.subtitle{
        color:#999;
        font-size: 14px;
        display: block; 
      }

    }

    a:hover{
      text-decoration: none;

      figure{
        -webkit-box-shadow: inset 0px 0px 34px 10px rgba(0,0,0,0.6);
        -moz-box-shadow: inset 0px 0px 34px 10px rgba(0,0,0,0.6);
        box-shadow: inset 0px 0px 34px 10px rgba(0,0,0,0.6);
      }
    }
  }
}

.gallery-images{   

  .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-xs-1, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9 {
      position: relative;
      min-height: 1px;
      padding-right: 5px;
      padding-left: 5px;
  }


    a.gallery-image-item{
      font-family: @titleFont;
      width:70px;
      height: 80px;
      display: inline-block;
      text-decoration: none;   
      text-align: center;   
      margin-top: 5px;
      margin-left:5px;

      figure, img{
        width:100%;
        height:80px;
        background-size: cover;
        background-position: center center;
        border-radius: 10px;
        margin: 5px 0px;
      }
    }
    a:hover{
      text-decoration: none;

      figure{
        -webkit-box-shadow: inset 0px 0px 34px 10px rgba(0,0,0,0.6);
        -moz-box-shadow: inset 0px 0px 34px 10px rgba(0,0,0,0.6);
        box-shadow: inset 0px 0px 34px 10px rgba(0,0,0,0.6);
      }
    }  
}

.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("../images/vimeo-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("../images/vimeo-play.png") no-repeat scroll 0 -58px transparent;
}
.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("../images/video-play.png") no-repeat scroll 0 0;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8;
}
.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1;
}
.lg-outer .lg-has-youtube .lg-video-play {
  background: url("../images/youtube-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("../images/youtube-play.png") no-repeat scroll 0 -60px transparent;
}