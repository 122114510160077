/*
  Notification
*/


.notification{
  position: fixed;
  bottom: 0px;
  right: 0px;
  background: #bc40db;
  color: #ffffff;
  text-align: center;
  width: 100%;
  padding: 10px;
  z-index: 1000;

  // -webkit-transition: notification-animation 0.5s; /* Safari */
  // transition: notification-animation 0.5s;

  i.fa-bullhorn{
    font-size: 22px;
    font-weight: bold;
    display: block;
    position: absolute;
    top: 18px;
    left: 20px;
    cursor: pointer;
  }

  i.fa-close{
    font-size: 18px;
    font-weight: normal;
    display: block;
    position: absolute;
    top: 18px;
    right: 20px;
    cursor: pointer;
    // -webkit-transition: notification-content-animation 1s; /* Safari */
    // transition: notification-content-animation 1s;
  }

  .btn{
    background: #ffffff;
    color: #bc40db;
    margin-left: 20px;
  }

  span{
    display: inline-block;
    // -webkit-transition: notification-content-animation 1s; /* Safari */
    // transition: notification-content-animation 1s;
  }

}

.notification.minimize-notif{
  width: 54px;
  height: 54px;
  span {
    display: none;
  }
  i.fa-close{
    display: none;
  }

}