/*
  Application dancepages styles
*/


.dancepage{
  .blocktitle{
    i{
      font-size: 34px;
      color: @swingColor;
      padding: 20px 0px;
    }
  }
  .white hr{
    height: 1px;
    border: 0px;
    border-bottom: 1px dashed #aaa;
  }

  .grey hr{
    height: 1px;
    border: 0px;
    border-bottom: 1px dashed #666;
  }

  .main-image{
    width:100%;
    height: 530px;
    position: absolute;
    top: -300px;

    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }

  .dancepage-nav{
    width:100%;
    height: 400px;
    position: relative;
    background: #F2F2F2;
    position: relative;

    .col-md-12{
      height: 400px;
    }

    .dancetype-lead{
      width:100%;
      height: 130px;
      position:absolute;
      left:0px;
      bottom: 50px;

      .dancetype-title{
        font-family: @titleFont;
        font-size: 26px;
        font-weight: bold;
        margin-bottom: 10px;
        color: #969696;
      }
    }

    h1{
      color: #5e5e5e;
      i{
        font-size: 28px;
        color: #000;
      }
    }

    nav{
      width:100%;
      height: 50px;
      position:absolute;
      left:0px;
      bottom:-10px;
      text-align: center;
      z-index: 25;

      ul{
        width:100%;
        display:inline-block;
        list-style-type: none;

        li{
          width:auto;
          height:50px;
          display:inline-block;
          position: relative;

          a{
            border-top:1px solid #fff;
            border-left:1px solid #fff;
            border-right:1px solid #fff;
            padding: 10px 20px 5px 20px;
            color:#000;
            font-size: 17px;
            font-family: @titleFont;
            font-weight: 300;
            text-decoration: none;
            border-top-left-radius: 7px;
            border-top-right-radius: 7px;
            background:#fff;
            display:block;
            width:100%;
            height: 100%;
            cursor: pointer;
            z-index: 40;

            i{
              color:#ccc;
              margin-right:10px;
            }
          }
          a:hover{
    /*        background:#ffc536;
            border-color: #ffc536;;
            color:#fff;*/
            i{
              color: #000;
              /*color:#fff;*/
            }
          }
        }
      }
    }
  }
}

/*
 DancePage unique stylings!
 */

/*
  Zumba styles
*/
.dancepage.zumba{
  .main-image{
    background-image: url('/images/subpagehero-img-salsa.png');
  }
  .blocktitle{
    i{
      color: @zumbaColor;
    }
  }
  nav ul li a i{
   color: @zumbaColor;
  }
  nav ul li a:hover{
   color: @zumbaColor;
   i{
     color: @zumbaColor;
   }
  }
  .btn-yellow{
    background:@zumbaColor;
  }
  .btn.btn-yellow-inverz{
    border-color: @zumbaColor;
    background-color: #fff;
    color:@zumbaColor;
  }
}
/*
  Tarsas styles
*/
.dancepage.tarsastanc{
  .main-image{
    background-image: url('/images/subpagehero-img-tarsas.png');
  }
  .blocktitle{
    i{

      color: @tarsasColor;
    }
  }
  nav ul li a i{
   color: @tarsasColor ;
  }
  nav ul li a:hover{
   color: @tarsasColor;
   i{
     color: @tarsasColor ;
   }
  }
  .btn-yellow{
    background:@tarsasColor;
  }
  .btn.btn-yellow-inverz{
    border-color: @tarsasColor;
    background-color: #fff;
    color:@tarsasColor;
  }
}
/*
  Salsa styles
*/
.dancepage.salsa{
  .main-image{
    background-image: url('/images/subpagehero-img-salsa.png');
  }
  .blocktitle{
    i{
      color: @salsaColor;
    }
  }
  nav ul li a i{
   color: @salsaColor ;
  }
  nav ul li a:hover{
   color: @salsaColor;
   i{
     color: @salsaColor ;
   }
  }
  .btn-yellow{
    background:@salsaColor;
  }
  .btn.btn-yellow-inverz{
    border-color: @salsaColor;
    background-color: #fff;
    color:@salsaColor;
  }
}
/*
  Swing styles
*/
.dancepage.swing,.dancepage.west-coast-swing{
  .main-image{
    background-image: url('/images/subpagehero-img-swing.png');
  }
  .blocktitle{
    i{
      color: @swingColor;
    }
  }
  nav ul li a i{
   color: @swingColor ;
  }
  nav ul li a:hover{
   color: @swingColor;
   i{
     color: @swingColor ;
   }
  }
  .btn-yellow{
    background:@swingColor;
  }
  .btn.btn-yellow-inverz{
    border-color: @swingColor;
    background-color: #fff;
    color:@swingColor;
  }
}
/*
  Rocky styles
*/
.dancepage.rocky{
  .main-image{
    background-image: url('/images/subpagehero-img-rocky.png');
  }
  .blocktitle{
    i{
      color: @rockyColor;
    }
  }
  nav ul li a i{
   color: @rockyColor;
  }
  nav ul li a:hover{
   color: @rockyColor;
   i{
     color: @rockyColor;
   }
  }
  .btn-yellow{
    background:@rockyColor;
  }
  .btn.btn-yellow-inverz{
    border-color: @rockyColor;
    background-color: #fff;
    color:@rockyColor;
  }
}
/*
  Bachata styles
*/
.dancepage.bachata{
  .main-image{
    background-image: url('/images/subpagehero-img-bachata.png');
  }
  .blocktitle{
    i{
      color: @bachataColor;
    }
  }
  nav ul li a i{
   color: @bachataColor;
  }
  nav ul li a:hover{
   color: @bachataColor;
   i{
     color: @bachataColor;
   }
  }
  .btn-yellow{
    background:@bachataColor;
  }
  .btn.btn-yellow-inverz{
    border-color: @bachataColor;
    background-color: #fff;
    color:@bachataColor;
  }
}
/*
  Fellépés styles
*/
.dancepage.fellepes{
  .main-image{
    background-image: url('/images/subpagehero-img-fellepes.png');
  }
  .blocktitle{
    i{
      color: @fellepesColor;
    }
  }
  nav ul li a i{
   color: @fellepesColor;
  }
  nav ul li a:hover{
   color: @fellepesColor;
   i{
     color: @fellepesColor;
   }
  }
  .btn-yellow{
    background:@fellepesColor;
  }
  .btn.btn-yellow-inverz{
    border-color: @fellepesColor;
    background-color: #fff;
    color:@fellepesColor;
  }
}
/*
  Szalagavato styles
*/
.dancepage.szalagavato{
  .main-image{
    background-image: url('/images/subpagehero-img-szalagavato.png');
  }
  .blocktitle{
    i{
      color: @szalagavatoColor;
    }
  }
  nav ul li a i{
   color: @szalagavatoColor;
  }
  nav ul li a:hover{
   color: @szalagavatoColor;
   i{
     color: @szalagavatoColor;
   }
  }
  .btn-yellow{
    // background:@szalagavatoColor;
    background:#84aac4;
  }
  .btn.btn-yellow-inverz{
    border-color: @szalagavatoColor;
    background-color: #fff;
    color:@szalagavatoColor;
  }
}
/*
  Eskuvoi styles
*/
.dancepage.eskuvoi{
  .main-image{
    background-image: url('/images/subpagehero-img-eskuvo.png');
  }
  .blocktitle{
    i{
      color: @eskuvoColor;
    }
  }
  nav ul li a i{
   color: @eskuvoColor ;
  }
  nav ul li a:hover{
   color: @eskuvoColor;
   i{
     color: @eskuvoColor ;
   }
  }
  .btn-yellow{
    // background:@eskuvoColor;
    background:#84aac4;
  }
  .btn.btn-yellow-inverz{
    border-color: @eskuvoColor;
    background-color: #fff;
    color:@eskuvoColor;
  }
}
