/*
  Application main style
*/
* {
  box-sizing: border-box;
}
*:focus {
  outline: none;
}
body {
  background: #F2F2F2;
}
h1,
h2,
h3,
h4,
h5 {
  font-family: "Comfortaa", cursive;
  padding-bottom: 20px;
}
@media (min-width: 1000px) {
  .container {
    width: 955px;
  }
}
@-moz-document url-prefix() {
  fieldset {
    display: table-cell;
  }
}
/*
  Import less styles
*/
/*
  Mixins
*/
/*
  Variables
*/
/*
  Application global style
*/
a.highlight {
  display: inline-block;
  color: #ffc536;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  margin-top: 5px;
  text-decoration: underline;
  font-weight: 400;
}
a.highlight:hover {
  text-decoration: none;
}
a.highlight-dark {
  display: inline-block;
  color: #ffc536;
  font-size: 16px;
  background: #999;
  padding: 5px 20px 5px 20px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  margin-top: 5px;
  text-decoration: underline;
  font-weight: 400;
}
a.highligh-dark:hover {
  text-decoration: none;
}
/*
  Top navigation + logo styles
*/
.site-top-container {
  position: relative;
  background: #f2f2f2;
  padding-top: 95px;
}
.popup-navigation-page-shade {
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  position: fixed;
  display: none;
  z-index: 50;
  top: 0px;
}
.site-top {
  padding-top: 10px;
  background: #f2f2f2;
  padding-bottom: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 49;
  transition: all 0.2s;
}
.site-top .container {
  position: relative;
}
.site-top .logo-container .logo {
  float: left;
  padding-right: 15px;
}
.site-top .logo-container .info {
  height: 55px;
  display: inline-block;
  border-left: 1px solid #aaa;
  padding-top: 5px;
  padding-left: 15px;
  font-size: 14px;
}
.site-top .logo-container .info a {
  color: #aaa;
}
.site-top .covid19 {
  text-align: right;
  padding: 5px 10px 0px 10px;
}
.site-top .covid19 img {
  max-height: 25px;
}
.site-top .menubars {
  font-size: 22px;
  color: #333;
}
.site-top .menubars small {
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  padding-right: 5px;
  display: inline-block;
}
.site-top .firstlesson-head {
  width: 169px;
  height: 89px;
  position: absolute;
  top: 0px;
  right: 10px;
  background: url('../images/ingyenes-ora/head-btn.png');
  z-index: 60;
  opacity: 0.8;
  -webkit-filter: grayscale(20%);
  filter: grayscale(20%);
}
.site-top .firstlesson-head a {
  width: 100%;
  height: 100%;
  display: block;
}
.site-top .firstlesson-head:hover {
  opacity: 1;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
}
.site-top .firstlesson-head-mobile .info {
  border-top: 1px solid #aaa;
  padding-left: 20px;
  line-height: 30px;
}
.site-top .firstlesson-head-mobile .info a {
  font-size: 12px;
  color: #aaa;
}
.site-top .firstlesson-head-mobile .firstlesson-head-btn {
  width: 171px;
  height: 61px;
  position: absolute;
  top: 1px;
  right: 15px;
  background: url('../images/ingyenes-ora/head-btn-mobile.png');
  z-index: 60;
}
.site-top .firstlesson-head-mobile .firstlesson-head-btn a {
  width: 100%;
  height: 100%;
  display: block;
}
.site-top.scrolled {
  padding-top: 10px;
  padding-bottom: 10px;
}
.site-top.scrolled:after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 1px;
  z-index: 49;
  box-shadow: 0px 0px 8px 2px #aaa;
}
.popup-nav-container {
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 51;
  display: none;
}
.popup-nav-container .popup-navigation {
  margin-top: -70px;
  display: none;
  width: 100%;
  border-radius: 15px;
  background: #f2f2f2;
  -webkit-box-shadow: 0px 0px 20px 1px rgba(110, 110, 110, 0.8);
  -moz-box-shadow: 0px 0px 20px 1px rgba(110, 110, 110, 0.8);
  box-shadow: 0px 0px 20px 1px rgba(110, 110, 110, 0.8);
  left: 0px;
  top: 0px;
  z-index: 100;
  float: left;
}
.popup-nav-container .popup-navigation .nav-head {
  width: 100%;
  height: 85px;
  background: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.popup-nav-container .popup-navigation .nav-head .logo-cont {
  padding: 20px 0px 0px 20px;
  width: 30%;
  float: left;
  text-align: left;
}
.popup-nav-container .popup-navigation .nav-head .btn-cont {
  padding: 20px 20px 0px 0px;
  width: 30%;
  float: right;
  text-align: right;
}
.popup-nav-container .popup-navigation .nav-head .btn-cont a {
  font-size: 32px;
  display: inline-block;
  margin-left: 20px;
}
.popup-nav-container .popup-navigation .nav-head .btn-cont a.facebook {
  color: #2d41a2;
}
.popup-nav-container .popup-navigation .nav-head .btn-cont a.map {
  color: #fd3535;
}
.popup-nav-container .popup-navigation .nav-head .btn-cont a.popup-navigation-close {
  color: #c3c3c3;
  margin-left: 35px;
  font-size: 40px;
}
.popup-nav-container .popup-navigation .nav-head .btn-cont a.popup-navigation-close:hover {
  color: #aaa;
}
.popup-nav-container .popup-navigation .nav-body {
  width: 100%;
  min-height: 185px;
  height: auto;
  padding-top: 30px;
}
.popup-nav-container .popup-navigation .nav-body .title {
  color: #5e5e5e;
  font-size: 20px;
  font-family: "Comfortaa", cursive;
  padding-top: 0px;
  padding-bottom: 10px;
  margin-top: 0px;
  border-bottom: 1px dashed #aaa;
}
.popup-nav-container .popup-navigation .nav-body .menuitem {
  font-family: "Comfortaa", cursive;
  width: 95%;
  font-weight: normal;
  display: block;
  font-size: 20px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #a5a5a5;
}
.popup-nav-container .popup-navigation .nav-body .menuitem.dance {
  font-family: "Comfortaa", cursive;
  font-weight: normal;
  width: 95%;
  display: block;
  font-size: 20px;
  padding-bottom: 2px;
  margin-bottom: 13px;
  line-height: 25px;
  color: #a5a5a5;
}
.popup-nav-container .popup-navigation .nav-body .menuitem:hover {
  color: #ffc536;
  text-decoration: none;
}
.popup-nav-container .popup-navigation .nav-body .submenuitem {
  font-family: "Comfortaa", cursive;
  display: block;
  font-size: 12px;
  color: #a5a5a5;
}
.popup-nav-container .popup-navigation .nav-body .submenuitem:hover {
  color: #ffc536;
  text-decoration: none;
}
.popup-nav-container .popup-navigation .nav-body ul {
  padding-left: 15px;
}
.popup-nav-container .popup-navigation .nav-body ul.dances {
  list-style-type: none;
}
.popup-nav-container .popup-navigation .nav-body ul.dances a {
  font-family: "Comfortaa", cursive;
  font-size: 22px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  color: #5e5e5e;
}
.popup-nav-container .popup-navigation .nav-body .mainbanner {
  width: 100%;
}
.popup-nav-container .popup-navigation .nav-body .mainbanner .banner-item {
  margin-top: 10px;
}
.popup-nav-container .popup-navigation .nav-body .popupnav-social-container {
  margin: 20px 0;
  text-align: center;
  font-size: 30px;
  display: none;
}
.popup-nav-container .popup-navigation .nav-body .popupnav-social-container .social-item {
  display: inline-block;
  margin: 0 20px;
}
.popup-nav-container .popup-navigation .nav-body .popupnav-social-container .social-item a {
  display: block;
  width: 100%;
  height: 100%;
}
.popup-nav-container .popup-navigation .nav-body .popupnav-social-container .social-item .facebook {
  color: #30402a;
}
.popup-nav-container .popup-navigation .nav-body .popupnav-social-container .social-item .location {
  color: #ff3636;
}
.popup-nav-container .popup-navigation .nav-body .popupnav-social-container .social-item .youtube {
  color: #ff3636;
}
.popup-nav-container .popup-navigation .nav-body .popupnav-social-container .social-item .instagram {
  color: #666;
}
/*
  Social links styles right side floating
*/
.social-link-container {
  position: fixed;
  right: -5px;
  top: 200px;
  width: 50px;
  height: 30px;
  background: transparent;
  z-index: 90;
}
.social-link-container .social-item {
  border-radius: 6px;
  background: #fff;
  margin-top: 3px;
  font-size: 20px;
  padding: 0px 10px;
  line-height: 30px;
  border: 1px solid #efefef;
  opacity: 1;
  margin-left: 70px;
  transition: all 0.2s;
}
.social-link-container .social-item a {
  display: block;
  width: 100%;
  height: 100%;
}
.social-link-container .social-item .facebook {
  color: #3b5998;
}
.social-link-container .social-item .location {
  color: #ff3636;
}
.social-link-container .social-item .youtube {
  color: #ff3636;
}
.social-link-container .social-item .instagram {
  width: 28px;
  height: 30px;
  padding-top: 3px;
}
.social-link-container .social-item .instagram i {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-image: url('../images/ico-instagram.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.social-link-container .social-item.loaded {
  margin-left: 0px;
  opacity: 1;
}
.social-link-container .social-item:hover {
  background: #efefef;
  margin-left: -5px;
}
/*
  Startpage hero styles
*/
.hero {
  width: 100%;
  height: 680px;
  position: relative;
  background: #f2f2f2;
}
.hero .top-shade {
  background-image: url("../images/goldance-hero-top.png");
  background-repeat: no-repeat;
  background-position: top;
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  top: -25px;
}
.hero .bottom-shade {
  background-image: url("../images/goldance-hero-bottom.png");
  background-repeat: no-repeat;
  background-position: bottom;
  display: block;
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0px;
}
.hero .highlight {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+47&1+0,0+46 */
  background: -moz-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 7%);
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 7%);
  /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 7%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  width: 100%;
  height: 585px;
  display: block;
  z-index: 15;
}
.hero .main-img {
  position: absolute;
  top: 0px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 505px;
  z-index: 15;
}
.hero-mobile {
  width: 100%;
  min-height: 250px;
  position: relative;
  background: #fff;
}
.hero-mobile .top-shade {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='100,0 0,100 0, 0' style='fill:%23f2f2f2' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: '';
  display: block;
  height: 100px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0px;
}
.hero-mobile .bottom-shade {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,100 100,0 100,100' style='fill:%23f2f2f2' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: '';
  display: block;
  height: 100px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0px;
}
/*
  Startpage hero dance types
*/
.hero {
  background: #333;
  -webkit-transition: all 0.3s;
  transition: all 0.5s;
}
.hero .dance-types {
  width: 100%;
  height: 100%;
  position: relative;
}
.hero .dance-types .dance-type {
  position: absolute;
  width: 220px;
  height: 70px;
  font-size: 32px;
  font-weight: 500;
  color: #fff;
  font-family: "Comfortaa", cursive;
  letter-spacing: 1px;
  line-height: 25px;
  z-index: 20;
}
.hero .dance-types .dance-type a {
  color: #fff;
  text-decoration: none;
}
.hero .dance-types .dance-type span {
  font-size: 20px;
  display: block;
  text-align: right;
  letter-spacing: 0.21px;
}
.hero .dance-types .dance-selector {
  position: absolute;
  z-index: 12;
  width: 420px;
  height: 80px;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff0000+49,ff0000+100&1+7,0+100 */
  background: -moz-linear-gradient(left, #ff0000 7%, rgba(255, 0, 0, 0.55) 49%, rgba(255, 0, 0, 0) 100%);
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #ff0000 7%, rgba(255, 0, 0, 0.55) 49%, rgba(255, 0, 0, 0) 100%);
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #ff0000 7%, rgba(255, 0, 0, 0.55) 49%, rgba(255, 0, 0, 0) 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0000', endColorstr='#00ff0000', GradientType=1);
  /* IE6-9 */
}
/*
  Dance selector types
*/
.dance-selector-right-bg {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff0000+0,ff0000+51&0+0,1+93 */
  background: -moz-linear-gradient(left, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0.55) 51%, #ff0000 93%) !important;
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0.55) 51%, #ff0000 93%) !important;
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0.55) 51%, #ff0000 93%) !important;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ff0000', endColorstr='#ff0000', GradientType=1) !important;
  /* IE6-9 */
}
.dance-selector.tarsastanc {
  top: 115px;
  left: 20px;
  transform: rotate(8deg);
  opacity: 0.8 !important;
}
.dance-selector.salsa {
  top: 210px;
  left: 20px;
  transform: rotate(3deg);
  opacity: 0.8 !important;
}
.dance-selector.swing,
.dance-selector.west-coast-swing {
  top: 310px;
  left: 20px;
  transform: rotate(-3deg);
  opacity: 0.8 !important;
}
.dance-selector.zumba {
  top: 400px;
  left: 20px;
  transform: rotate(-8deg);
  opacity: 0.8 !important;
}
.dance-selector.bachata {
  top: 115px;
  right: 20px;
  transform: rotate(-8deg);
  opacity: 0.8 !important;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff0000+0,ff0000+51&0+0,1+93 */
  background: -moz-linear-gradient(left, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0.55) 51%, #ff0000 93%) !important;
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0.55) 51%, #ff0000 93%) !important;
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0.55) 51%, #ff0000 93%) !important;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ff0000', endColorstr='#ff0000', GradientType=1) !important;
  /* IE6-9 */
}
.dance-selector.rocky {
  top: 210px;
  right: 20px;
  transform: rotate(-3deg);
  opacity: 0.8 !important;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff0000+0,ff0000+51&0+0,1+93 */
  background: -moz-linear-gradient(left, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0.55) 51%, #ff0000 93%) !important;
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0.55) 51%, #ff0000 93%) !important;
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0.55) 51%, #ff0000 93%) !important;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ff0000', endColorstr='#ff0000', GradientType=1) !important;
  /* IE6-9 */
}
.dance-selector.eskuvoi {
  top: 300px;
  right: 10px;
  transform: rotate(8deg);
  opacity: 0.8 !important;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff0000+0,ff0000+51&0+0,1+93 */
  background: -moz-linear-gradient(left, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0.55) 51%, #ff0000 93%) !important;
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0.55) 51%, #ff0000 93%) !important;
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0.55) 51%, #ff0000 93%) !important;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ff0000', endColorstr='#ff0000', GradientType=1) !important;
  /* IE6-9 */
}
.dance-selector.szalagavato {
  top: 390px;
  right: 20px;
  transform: rotate(8deg);
  opacity: 0.8 !important;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff0000+0,ff0000+51&0+0,1+93 */
  background: -moz-linear-gradient(left, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0.55) 51%, #ff0000 93%) !important;
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0.55) 51%, #ff0000 93%) !important;
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0.55) 51%, #ff0000 93%) !important;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ff0000', endColorstr='#ff0000', GradientType=1) !important;
  /* IE6-9 */
}
.dance-selector.fellepes {
  top: 310px;
  right: 20px;
  transform: rotate(3deg);
  opacity: 0.8 !important;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ff0000+0,ff0000+51&0+0,1+93 */
  background: -moz-linear-gradient(left, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0.55) 51%, #ff0000 93%) !important;
  /* FF3.6-15 */
  background: -webkit-linear-gradient(left, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0.55) 51%, #ff0000 93%) !important;
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, rgba(255, 0, 0, 0) 0%, rgba(255, 0, 0, 0.55) 51%, #ff0000 93%) !important;
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ff0000', endColorstr='#ff0000', GradientType=1) !important;
  /* IE6-9 */
}
/*
  Dance type titles in hero
*/
.dance-type.tarsastanc {
  top: 130px;
  left: 30px;
  transform: rotate(8deg);
}
.dance-type.salsa {
  top: 230px;
  left: 30px;
  transform: rotate(3deg);
}
.dance-type.swing,
.dance-type.west-coast-swing {
  top: 330px;
  left: 30px;
  transform: rotate(-3deg);
}
.dance-type.zumba {
  top: 440px;
  left: 40px;
  transform: rotate(-8deg);
}
.dance-type.bachata {
  top: 130px;
  right: 50px;
  transform: rotate(352deg);
}
.dance-type.rocky {
  top: 230px;
  right: 30px;
  transform: rotate(357deg);
}
.dance-type.eskuvoi {
  top: 330px;
  right: 30px;
  transform: rotate(8deg);
}
.dance-type.szalagavato {
  top: 410px;
  right: 60px;
  transform: rotate(8deg);
}
/*
  Hero styles for each dance style
*/
.hero.zumba {
  background-color: #28a372;
}
.hero.zumba .main-img {
  background-image: url('../images/dance-zumba.png');
}
.hero.tarsastanc {
  background-color: #FD6100;
}
.hero.tarsastanc .main-img {
  background-image: url('../images/dance-tarsas.png');
}
.hero.salsa {
  background-color: #AC0302;
}
.hero.salsa .main-img {
  background-image: url('../images/dance-salsa.png');
}
.hero.swing,
.hero.west-coast-swing {
  background-image: url('../images/swing-hero-bg.jpg');
  background-repeat: repeat;
}
.hero.swing .main-img,
.hero.west-coast-swing .main-img {
  background-image: url('../images/dance-westcoast.png');
}
.hero.rocky {
  background-color: #00AEFF;
}
.hero.rocky .main-img {
  background-image: url('../images/dance-rocky.png');
}
.hero.bachata {
  background-color: #b732d9;
}
.hero.bachata .main-img {
  background-image: url('../images/dance-batchata.png');
}
.hero.fellepes {
  background-color: #e5d5a0;
}
.hero.fellepes .main-img {
  background-image: url('../images/dance-fellepes.png');
}
.hero.szalagavato {
  background-color: #e5d5a0;
}
.hero.szalagavato .main-img {
  background-image: url('../images/dance-szalagavato.png');
}
.hero.eskuvoi {
  background-color: #cfdde5;
}
.hero.eskuvoi .main-img {
  background-image: url('../images/dance-eskuvoi.png');
}
/*
  Application hero navigation
*/
.hero nav {
  width: 100%;
  height: 45px;
  position: absolute;
  bottom: 124px;
  left: 0px;
  text-align: center;
  z-index: 25;
  transform: rotate(-4.9deg);
}
.hero nav ul {
  width: 100%;
  display: inline-block;
  list-style-type: none;
}
.hero nav ul li {
  width: auto;
  height: 45px;
  display: inline-block;
  position: relative;
}
.hero nav ul li a {
  border-top: 1px solid #F2F2F2;
  border-left: 1px solid #F2F2F2;
  border-right: 1px solid #F2F2F2;
  padding: 8px 20px 5px 20px;
  color: #000;
  font-size: 17px;
  font-family: "Comfortaa", cursive;
  font-weight: 300;
  text-decoration: none;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background: #F2F2F2;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 40;
}
.hero nav ul li a i {
  color: #ccc;
  margin-right: 5px;
  font-size: 19px;
}
.hero nav ul li a:hover i {
  color: #ffc536;
}
/*
  Application subpage hero navigation
*/
.subhero {
  width: 100%;
  height: 175px;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-color: #f2f2f2;
  border-bottom: 10px solid #e9e9e9;
}
.subhero .top-shade {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='100,0 0,100 0, 0' style='fill:#f2f2f2' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: '';
  display: block;
  height: 60px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0px;
}
.subhero nav {
  width: 100%;
  height: 40px;
  position: absolute;
  bottom: -5px;
  left: 0px;
  text-align: center;
  z-index: 25;
}
.subhero nav ul {
  width: 100%;
  display: inline-block;
  list-style-type: none;
}
.subhero nav ul li {
  width: auto;
  height: 45px;
  display: inline-block;
  position: relative;
}
.subhero nav ul li a {
  border-top: 1px solid #F2F2F2;
  border-left: 1px solid #F2F2F2;
  border-right: 1px solid #F2F2F2;
  padding: 10px 20px 5px 20px;
  color: #000;
  font-size: 17px;
  font-family: "Comfortaa", cursive;
  font-weight: 300;
  text-decoration: none;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background: #F2F2F2;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 40;
}
.subhero nav ul li a i {
  color: #ccc;
  margin-right: 5px;
  font-size: 19px;
}
.subhero nav ul li a:hover {
  background: #ffc536;
  border-color: #ffc536;
  color: #fff;
}
.subhero nav ul li a:hover i {
  color: #fff;
}
/*
  Application start-here style
*/
.start-here {
  width: 100%;
  padding-bottom: 20px;
  margin-top: -40px;
  position: relative;
  background: transparent;
}
.start-here img {
  width: 100%;
  height: auto;
}
.start-here h2 {
  text-align: center;
  font-family: "Comfortaa", cursive;
  font-size: 24px;
  color: #5e5e5e;
}
.start-here h2 a {
  color: #5e5e5e;
}
.start-here h2 span {
  color: #000;
}
.start-here .icon-item {
  margin-top: 40px;
}
.start-here .icon-item .icon {
  width: 100%;
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
.start-here .icon-item .icon a {
  display: block;
  width: 100%;
  height: 100%;
}
.start-here .icon-item .icon.kalkulator {
  background-image: url('../images/ico-calc.png');
}
.start-here .icon-item .icon.arak {
  background-image: url('../images/ico-araink.png');
}
.start-here .icon-item .icon.akciok {
  background-image: url('../images/ico-akcioink.png');
}
.start-here .icon-item .icon.orarend {
  background-image: url('../images/ico-orarend.png');
}
.start-here .title {
  padding-top: 5px;
  font-size: 12px;
  font-family: "Comfortaa", cursive;
  font-weight: 300;
  text-align: center;
  width: 100%;
}
/*
  Startpage highlighted services
*/
.highlight-banner {
  height: 310px;
  width: 100%;
  overflow: hidden;
  text-align: center;
  background-size: cover;
  background-position: center center;
}
.highlight-banner a {
  width: 100%;
  height: 100%;
  display: block;
}
/*
  Buttons
*/
.btn.btn-dashed {
  border: 1px dashed #a6a6a6;
  background: #fff;
  padding: 3px 10px;
  display: inline-block;
  width: auto;
  border-radius: 10px;
  font-size: 11px;
  text-transform: uppercase;
  color: #333;
  text-decoration: none;
  font-family: "Comfortaa", cursive;
  letter-spacing: 1px;
}
.btn.btn-dashed:hover {
  border: 1px dashed #a6a6a6;
  background: #ffc536;
  color: #fff;
}
.btn.btn-yellow {
  font-family: "Comfortaa", cursive;
  background: #ffc536;
  display: inline-block;
  width: auto;
  border-radius: 10px;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  letter-spacing: 1px;
}
.btn.btn-yellow-inverz {
  font-family: "Comfortaa", cursive;
  border-color: #ffc536;
  background-color: #fff;
  display: inline-block;
  width: auto;
  border-radius: 10px;
  text-transform: uppercase;
  color: #ffc536;
  text-decoration: none;
  letter-spacing: 1px;
}
.btn.btn-fullrow {
  width: 100%;
}
.btn.btn-allevent {
  padding-top: 10px;
  text-decoration: none;
  color: #ffffff;
  letter-spacing: 1px;
  border-radius: 10px;
  text-transform: uppercase;
  display: inline-block;
  font-family: "Comfortaa", cursive;
  background: #e3324e;
  background: linear-gradient(90deg, #e3324e 0%, #b72727 60%);
  font-size: 110%;
  transition: all 1s;
}
.btn.btn-allevent i {
  font-size: 125%;
  font-weight: bold;
}
.btn-to-top {
  position: fixed;
  display: none;
  bottom: 50px;
  right: 20px;
  font-size: 50px;
  color: #888;
  cursor: pointer;
}
.btn.disabled,
.btn[disabled] {
  background: #ddd !important;
  border-color: #666 !important;
  color: #666 !important;
}
.btn.disabled i,
.btn[disabled] i {
  display: inline-block !important;
  font-size: 19px;
}
input:disabled {
  background: #ddd;
  border-color: #ccc;
}
.btn.btn-yellow.btn-zumba {
  background: #28a372;
}
.btn.btn-yellow-inverz.btn-zumba {
  border-color: #28a372;
  color: #28a372;
}
.btn.btn-yellow.btn-tarsas {
  background: #ff6c00;
}
.btn.btn-yellow-inverz.btn-tarsas {
  border-color: #ff6c00;
  color: #ff6c00;
}
.btn.btn-yellow.btn-salsa {
  background: #ac0302;
}
.btn.btn-yellow-inverz.btn-salsa {
  border-color: #ac0302;
  color: #ac0302;
}
.btn.btn-yellow.btn-westcoast {
  background: #ffc536;
}
.btn.btn-yellow-inverz.btn-westcoast {
  border-color: #ffc536;
  color: #ffc536;
}
.btn.btn-yellow.btn-bachata {
  background: #b732d9;
}
.btn.btn-yellow-inverz.btn-bachata {
  border-color: #b732d9;
  color: #b732d9;
}
.btn.btn-yellow.btn-rocky {
  background: #00AEFF;
}
.btn.btn-yellow-inverz.btn-rocky {
  border-color: #00AEFF;
  color: #00AEFF;
}
.btn.btn-yellow.btn-versenyzo {
  background: #8cb5b1;
}
.btn.btn-yellow-inverz.btn-versenyzo {
  border-color: #8cb5b1;
  color: #8cb5b1;
}
/*
  Buttons
*/
.topmargin10 {
  margin-top: 10px;
}
.topmargin20 {
  margin-top: 20px;
}
.block {
  display: block;
}
.bottompadding10 {
  padding-bottom: 10px;
}
.bottompadding20 {
  padding-bottom: 20px;
}
.toppadding20 {
  padding-top: 20px;
}
.topshadow {
  -moz-box-shadow: inset 0 10px 20px -10px #aaa;
  -webkit-box-shadow: inset 0 10px 20px -10px #aaa;
  box-shadow: inset 0 10px 20px -10px #aaa;
}
.bottomshadow {
  -moz-box-shadow: inset 0 -10px 20px -10px #aaa;
  -webkit-box-shadow: inset 0 -10px 20px -10px #aaa;
  box-shadow: inset 0 -10px 20px -10px #aaa;
}
a.full-clickable {
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.btn-max {
  width: 100%;
  padding: 40px;
  font-size: 145%;
}
.text-hightlight {
  font-family: "Comfortaa", cursive;
  color: #ffc536;
}
.text-mid {
  font-size: 20px;
}
.text-lg {
  font-size: 24px;
}
.font-weight-bold {
  font-weight: bold;
}
/*
 Content styles
*/
.content {
  width: 100%;
  padding: 20px 0px;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  color: #494949;
}
.content .assetbox {
  width: 100%;
  text-align: center;
}
.content .assetbox img {
  max-width: 100%;
  height: auto;
}
.content .assetbox .title {
  padding: 10px;
  font-size: 12px;
  color: #a2a2a2;
}
.content p img {
  margin: 10px;
  max-width: 100%;
  height: auto;
}
.content h1 img,
.content h2 img,
.content h3 img,
.content h4 img,
.content h5 img,
.content h6 img {
  margin: 10px;
}
.content .blocktitle {
  font-size: 28px;
  font-family: "Comfortaa", cursive;
  font-weight: 100;
}
.content .more-btn-with-sepa,
.content .more-btn-with-sepa-close {
  width: 100%;
  position: relative;
  text-align: center;
  margin: 20px 0px;
}
.content .more-btn-with-sepa .separator,
.content .more-btn-with-sepa-close .separator {
  border: 0px;
  margin: 0px;
  width: 100%;
  border-bottom: 1px dashed #a6a6a6;
  height: 2px;
  display: inline-block;
}
.content .more-btn-with-sepa .btn-dashed,
.content .more-btn-with-sepa-close .btn-dashed {
  display: inline-block;
  margin-top: -30px;
}
.content .more-content {
  display: none;
}
/* Helper classes */
.content.grey {
  background: #F2F2F2;
}
.content.white {
  background: #fff;
}
/*
Instructor styles
*/
.clear-filter-box {
  margin-top: 22px;
}
.clear-filter-box .clear-filter {
  cursor: pointer;
  display: none;
}
.instructor-item {
  padding: 5px;
}
.instructor-item .inner {
  position: relative;
  margin: 10px;
  height: 300px;
  width: 100%;
  background-image: url('../images/teacher-pic-holder.png');
  background-position: center center;
  background-size: cover;
  padding: 0px;
}
.instructor-item .inner .description {
  display: none;
  width: 350px;
  height: 300px;
  border: 2px solid #e2e2e2;
  line-height: 16px;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin-top: 15px;
  font-weight: 100;
}
.instructor-item .inner .data {
  display: none;
  padding: 10px;
  height: 300px;
  width: 100%;
  background-color: rgba(230, 230, 230, 0.9);
}
.instructor-item .inner .data .info {
  overflow-y: scroll;
  height: 230px;
}
.instructor-item .inner .data .info h4 {
  width: 100%;
  font-size: 26px;
  color: #494949;
  font-family: "Comfortaa", cursive;
}
.instructor-item .inner .data .info ul.instructor-details {
  width: 100%;
  height: 200px;
  padding-left: 0px;
  margin-left: 0px;
}
.instructor-item .inner .data .info ul.instructor-details .label {
  font-size: 12px;
  color: #494949;
  font-family: "Comfortaa", cursive;
  display: inline-block;
  width: 38%;
  text-align: left;
  vertical-align: top;
}
.instructor-item .inner .data .info ul.instructor-details .value {
  font-size: 12px;
  color: #494949;
  font-weight: 300;
  font-family: "Comfortaa", cursive;
  display: inline-block;
  width: 60%;
  text-align: left;
  vertical-align: top;
}
.instructor-item .inner .data .info ul.instructor-details .value p {
  padding: 0px 0px;
  margin: 0px 0px 2px 0px;
}
.instructor-item .inner .data .more {
  padding-top: 10px;
  height: 30px;
}
.instructor-item.bottomborder {
  border-bottom: 1px dashed #666;
}
/*
  Footer style
*/
footer {
  background-color: #393939;
  min-height: 300px;
  margin-bottom: 60px;
  padding: 10px 0px 60px 0px;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  color: #fff;
  position: relative;
}
footer .bottomborder {
  border-bottom: 1px dashed #666;
}
footer .h4 {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  display: block;
  margin-bottom: 10px;
  padding: 0px;
}
footer .h5 {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  display: block;
  margin-top: 30px;
  padding: 0px;
}
footer a {
  color: #fff;
  text-decoration: underline;
}
footer a:hover {
  color: #ffc536;
}
footer nav {
  width: 100%;
}
footer nav a {
  display: inline-block;
  color: #fff;
  font-size: 16px;
  font-weight: 200;
  margin-right: 30px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  margin-top: 5px;
}
footer nav a:hover {
  text-decoration: none;
  color: #ffc536;
}
footer .footerlogo {
  line-height: 60px;
}
footer .goldance-logo {
  line-height: normal;
  vertical-align: middle;
  display: inline-block;
  width: 100%;
  max-width: 158px;
  height: 40px;
  background-image: url('/template/images/logo-footer.png');
  background-repeat: no-repeat;
  background-size: contain;
}
footer .goldance-logo a {
  width: 100%;
  height: 100%;
  display: block;
}
footer .gdtancsport-logo {
  line-height: normal;
  vertical-align: middle;
  display: inline-block;
  width: 100%;
  max-width: 80px;
  height: 60px;
  margin-top: 5px;
  margin-left: 30px;
  background-image: url('/template/images/gd-tancsport-logo.png');
  background-repeat: no-repeat;
  background-size: contain;
}
footer .gdtancsport-logo a {
  width: 100%;
  height: 100%;
  display: block;
}
footer .icons a {
  font-size: 50px;
  color: #fefefe;
  margin-right: 40px;
}
footer .icons a:hover {
  color: #ccc;
}
footer:before {
  width: 100%;
  height: 4px;
  display: block;
  content: "";
  background: url('/template/images/footer-top.png');
  background-repeat: repeat-x;
  position: absolute;
  top: -4px;
  left: 0px;
}
footer:after {
  width: 100%;
  height: 4px;
  display: block;
  content: "";
  background: url('/template/images/footer-bottom.png');
  background-repeat: repeat-x;
  position: absolute;
  bottom: -4px;
  left: 0px;
}
/*
  Application form elements style
*/
.selectric-wrapper {
  margin-top: 10px;
}
.selectric-wrapper .selectric {
  background: fff;
}
.selectric-wrapper .selectric .label {
  height: 30px;
  font-size: 16px;
  color: #999;
  font-family: "Comfortaa", cursive;
  font-weight: 100;
  text-align: left;
  line-height: 25px;
}
.selectric-wrapper .selectric .button {
  background: #f1ba33;
  margin: 1px;
  height: 28px;
  line-height: 28px;
  font-size: 24px;
  color: #fff;
}
.selectric-wrapper .selectric .button:after {
  content: none;
}
.selectric-wrapper.selectric-disabled .button {
  background: #333;
  margin: 1px;
  height: 28px;
  line-height: 28px;
  font-size: 24px;
  color: #fff;
}
input.timepicker {
  width: 160px;
  height: 30px;
  border: 1px solid #ddd;
  background: #f8f8f8;
}
/*
  Application discount-ul list style
*/
.discount-deals .fa-li {
  color: #ff7e00;
}
.discount-deals li {
  font-size: 13px;
  margin-top: 10px;
}
.discount-deals li strong {
  display: block;
}
/*
  Application orarend styles
    - form and discount styles:
      @import 'form-elements';
      @import 'discount-ul';
*/
.orarend-block {
  position: relative;
  height: 500px;
}
.orarend-block .table-responsive {
  overflow-x: visible !important;
}
.orarend-block.table-responsive {
  overflow-x: visible !important;
  overflow-y: scroll !important;
}
.timetable-placeholder {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 400px;
  padding-top: 150px;
  text-align: center;
  font-size: 60px;
  color: #ddd;
}
table.orarend {
  width: 100%;
  font-size: 12px;
  opacity: 0;
  transition: all 0.3s;
}
table.orarend tbody {
  width: 100%;
}
table.orarend th {
  background: #ffedc2;
  text-transform: uppercase;
  font-family: "Comfortaa", cursive;
  border-bottom: 1px solid #ddd !important;
}
table.orarend .data-row td {
  border-bottom: 1px dashed #ddd !important;
  border-top: 0px !important;
  padding: 4px;
}
table.orarend .data-row td .comment {
  font-size: 10px;
  font-weight: bold;
  color: #999;
}
table.orarend tr {
  position: relative;
}
table.orarend .hidden-row {
  opacity: 0.2;
  position: relative;
}
table.orarend .hidden-row .btn-yellow {
  font-size: 10px;
  padding: 5px 5px;
  background: #ccc;
}
table.orarend .selected-row {
  opacity: 1;
  border: 1px solid #ffc536 !important;
}
table.orarend .selected-row .form-control {
  width: 100%;
  height: 25px;
  font-size: 12px;
  margin-top: 3px;
}
table.orarend .selected-row .timetable-reg-cancel {
  margin-top: 5px;
}
table.orarend .selected-row .error {
  border: 1px solid #ff0000;
}
table.orarend .selected-row .valid {
  border: 1px solid #999;
}
table.orarend td .btn-yellow {
  font-size: 10px;
  padding: 5px 5px;
}
table.orarend .btn-yellow.sent {
  font-size: 10px;
  padding: 5px 20px;
  background: #ccc;
}
table.orarend.loaded {
  opacity: 1;
  height: auto;
}
.orarendKerulet span.select-label {
  float: left;
}
.orarendKerulet .selectric-wrapper {
  display: inline-block;
}
span.select-label {
  display: block;
  float: none;
  padding-right: 8px;
  padding-top: 15px;
  font-family: "Comfortaa", cursive;
  font-size: 18px;
  color: #999;
}
/* Dancetype selector page - used in firstlessonreg and newlessonreg too*/
.dancetype-selector {
  text-align: center;
  /* dancetype box types */
}
.dancetype-selector .dancetype {
  text-align: center;
  width: 180px;
  display: inline-block;
}
.dancetype-selector .dancetype .image {
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  cursor: pointer;
}
.dancetype-selector .dancetype .image a {
  width: 100%;
  height: 100%;
  display: block;
}
.dancetype-selector .dancetype .btn-yellow {
  color: #fff;
}
.dancetype-selector .dancetype.zumba .image {
  background-image: url('../images/dance-zumba.png');
}
.dancetype-selector .dancetype.zumba .btn-yellow {
  background-color: #28a372;
}
.dancetype-selector .dancetype.tarsas .image {
  background-image: url('../images/dance-tarsas.png');
}
.dancetype-selector .dancetype.tarsas .btn-yellow {
  background-color: #ff6c00;
}
.dancetype-selector .dancetype.tarsastanc .image {
  background-image: url('../images/dance-tarsas.png');
}
.dancetype-selector .dancetype.tarsastanc .btn-yellow {
  background-color: #ff6c00;
}
.dancetype-selector .dancetype.salsa .image {
  background-image: url('../images/dance-salsa.png');
}
.dancetype-selector .dancetype.salsa .btn-yellow {
  background-color: #ac0302;
}
.dancetype-selector .dancetype.westcoast .image {
  background-image: url('../images/dance-westcoast.png');
}
.dancetype-selector .dancetype.westcoast .btn-yellow {
  background-color: #ffc536;
}
.dancetype-selector .dancetype.swing .image,
.dancetype-selector .dancetype.west-coast-swing .image {
  background-image: url('../images/dance-westcoast.png');
}
.dancetype-selector .dancetype.swing .btn-yellow,
.dancetype-selector .dancetype.west-coast-swing .btn-yellow {
  background-color: #ffc536;
}
.dancetype-selector .dancetype.west-coast-swing .image {
  background-image: url('../images/dance-westcoast.png');
}
.dancetype-selector .dancetype.west-coast-swing .btn-yellow {
  background-color: #ffc536;
}
.dancetype-selector .dancetype.bachata .image {
  background-image: url('../images/dance-batchata.png');
}
.dancetype-selector .dancetype.bachata .btn-yellow {
  background-color: #b732d9;
}
.dancetype-selector .dancetype.rocky .image {
  background-image: url('../images/dance-rocky.png');
}
.dancetype-selector .dancetype.rocky .btn-yellow {
  background-color: #00AEFF;
}
.dancetype-selector .dancetype.versenyzo .image {
  background-image: url('../images/dance-versenyzo.png');
}
.dancetype-selector .dancetype.versenyzo .btn-yellow {
  background-color: #8cb5b1;
}
.timetable.dancetype-selector .dancetype {
  width: 123px;
}
.timetable.dancetype-selector .dancetype .image {
  height: 120px;
}
.timetable.dancetype-selector .dancetype .btn-yellow {
  font-size: 12px;
  width: 100%;
}
.timetable-content-panels .pricebox {
  border-left: 1px solid #999;
}
.timetable-content-panels .sale {
  color: #aa0000;
  font-weight: bold;
}
.timetable-content-panels h4.sale {
  text-align: center;
}
/*
  Application price-calculator-component style
*/
.berlet-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.berlet-info .card-single {
  width: 340px;
  margin-top: 25px;
  text-align: center;
}
.berlet-info .card-single .card-item {
  width: 100%;
  display: inline-block;
  position: relative;
  padding-bottom: 10px;
}
.berlet-info .card-single .card-item .image {
  width: 100%;
  height: 129px;
  box-shadow: 0 4px 2px -2px gray;
  background-position: bottom;
}
.berlet-info .card-single .card-item .price {
  text-transform: uppercase;
  font-size: 19px;
  padding-top: 10px;
  line-height: 24px;
}
.berlet-info .card-single .card-item .price span {
  font-size: 20px;
  font-weight: 400;
}
.berlet-info .card-single .card-item .price span.sale {
  color: #AA0000;
  font-weight: bold;
  font-size: 30px;
}
.berlet-info .card-single .card-item .info {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: none;
  border: 2px solid #000;
  border-radius: 10px;
}
.berlet-info .card-single .card-item .info .text {
  border-radius: 5px 5px 0px 0px;
  padding: 15px 10px;
  text-transform: uppercase;
  width: 100%;
  height: 129px;
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  overflow: hidden;
}
.berlet-info .card-single .card-item.foberlet .image {
  background-image: url('/template/images/calc-foberlet.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.berlet-info .card-single .card-item.alk6 .image {
  background-image: url('/template/images/calc-6-alkalom.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.berlet-info .card-single .card-item.alk9 .image {
  background-image: url('/template/images/calc-9-alkalom.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.berlet-info .card-single:first-child {
  margin-top: 0px;
}
.berlet-info .card-multi {
  width: 100%;
  margin-top: 25px;
  text-align: center;
}
.berlet-info .card-multi .card-item {
  width: 49%;
  display: inline-block;
  position: relative;
  padding-bottom: 7px;
}
.berlet-info .card-multi .card-item .image {
  width: 100%;
  height: 80px;
  box-shadow: 0 4px 2px -2px gray;
  background-position: bottom;
}
.berlet-info .card-multi .card-item .price {
  padding-top: 10px;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 22px;
}
.berlet-info .card-multi .card-item .price span {
  font-size: 16px;
  font-weight: 400;
}
.berlet-info .card-multi .card-item .price span.sale {
  color: #AA0000;
  font-weight: bold;
  font-size: 22px;
}
.berlet-info .card-multi .card-item .info {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: none;
  text-transform: uppercase;
  border: 2px solid #000;
  border-radius: 10px;
  font-size: 10px;
  line-height: 12px;
  color: #fff;
}
.berlet-info .card-multi .card-item .info .text {
  border-radius: 5px 5px 0px 0px;
  width: 100%;
  height: 80px;
  padding: 5px 5px;
  position: absolute;
  background: rgba(0, 0, 0, 0.8);
  overflow: hidden;
}
.berlet-info .card-multi .card-item.kieg1 .image {
  background-image: url('/template/images/calc-kartya1.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.berlet-info .card-multi .card-item.kieg2 .image {
  background-image: url('/template/images/calc-kartya2.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.berlet-info .card-multi .card-item.alkalmi1 .image {
  background-image: url('/template/images/calc-alkalmidij.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.berlet-info .card-multi .card-item.alkalmi2 .image {
  background-image: url('/template/images/calc-alkalmidijzumba.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.price-calculator {
  font-family: "Comfortaa", cursive;
  padding-top: 30px;
}
.price-calculator .dance-type {
  margin-top: 10px;
}
.price-calculator .dance-type p {
  text-align: center;
}
.price-calculator .dance-type p b {
  font-size: 18px;
  font-weight: 200;
}
.price-calculator .dance-type p span.count {
  font-size: 32px;
  font-weight: 400;
}
.price-calculator .dance-type span.min-count {
  font-size: 14px;
  display: inline-block;
  padding-right: 15px;
}
.price-calculator .dance-type span.max-count {
  font-size: 14px;
  display: inline-block;
  padding-left: 15px;
}
.price-calculator .dance-type .slider.slider-horizontal {
  width: 80%;
}
.price-calculator .dance-type .slider-handle {
  position: absolute;
  top: 0;
  width: 20px;
  height: 20px;
  border: 3px solid #fff;
  background: #ffc536;
}
.price-calculator .dance-type .slider-selection {
  background: #ffc536;
}
.price-calculator .calculateresult {
  padding-top: 60px;
}
.price-calculator .calculateresult .subtitle {
  font-size: 20px;
  outline: none;
}
.price-calculator .calculateresult .resulttext {
  font-size: 18px;
}
.price-calculator .calculateresult .resultinfo {
  font-size: 14px;
}
.price-calculator .calculateresult .resulttitle {
  font-size: 28px;
  font-weight: bold;
}
.price-calculator .calculateresult .resultcalculation {
  font-size: 45px;
  color: #ffc536;
}
/*
  Application fellepesek styles
*/
.fellepes-item {
  padding: 15px;
}
.fellepes-item a {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 200px;
}
/*
  Buttons
*/
.contact-info {
  border-top: 1px dashed #a6a6a6;
  border-bottom: 1px dashed #a6a6a6;
  padding: 20px 10px;
  margin: 0px 0px;
}
.contact-info a {
  font-family: "Comfortaa", cursive;
  letter-spacing: 1px;
  font-size: 18px;
  color: #a6a6a6;
}
/*
  Application orarend styles
    - form and discount styles:
      @import 'form-elements';
      @import 'discount-ul';
*/
.events-mobile {
  display: none;
}
.events,
.right-hover-column-body .banners {
  width: 100%;
  min-height: 270px;
  margin-top: 30px;
}
.events .banner-item,
.right-hover-column-body .banners .banner-item {
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
}
.events .banner-item img,
.right-hover-column-body .banners .banner-item img {
  max-width: 227px;
}
.events .banner-item:first-child,
.right-hover-column-body .banners .banner-item:first-child {
  margin-top: 0px;
}
.events-mobile .banners,
.right-hover-column-body .banners {
  width: 100%;
  min-height: 270px;
  margin-top: 20px;
}
.events-mobile .banners .banner-item,
.right-hover-column-body .banners .banner-item {
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
}
.events-mobile .banners .banner-item:first-child,
.right-hover-column-body .banners .banner-item:first-child {
  margin-top: 0px;
}
.events-mobile .events,
.right-hover-column-body .events {
  width: 100%;
}
.events-mobile .events .title,
.right-hover-column-body .events .title {
  font-family: "Comfortaa", cursive;
  width: 100%;
  font-size: 19px;
  color: #fff;
  background: #fec436;
  padding: 5px 5px;
}
.events-mobile .events .event-item,
.right-hover-column-body .events .event-item {
  width: 100%;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #878787;
}
.events-mobile .events .event-item .description,
.right-hover-column-body .events .event-item .description {
  width: 82%;
  display: inline-block;
  position: relative;
}
.events-mobile .events .event-item .description a.event-link,
.right-hover-column-body .events .event-item .description a.event-link {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
}
.events-mobile .events .event-item .description .datetime,
.right-hover-column-body .events .event-item .description .datetime {
  font-size: 14px;
  color: #000;
  font-family: "Comfortaa", cursive;
}
.events-mobile .events .event-item .description .datetime span,
.right-hover-column-body .events .event-item .description .datetime span {
  color: #878787;
}
.events-mobile .events .event-item .description .city,
.right-hover-column-body .events .event-item .description .city {
  font-weight: bold;
  font-size: 12px;
}
.events-mobile .events .event-item .description .address,
.right-hover-column-body .events .event-item .description .address {
  font-size: 12px;
}
.events-mobile .events .event-item .icons,
.right-hover-column-body .events .event-item .icons {
  width: 15%;
  display: inline-block;
  text-align: center;
  font-size: 20px;
}
.events-mobile .events .event-item .icons .fb,
.right-hover-column-body .events .event-item .icons .fb {
  margin-left: 20px;
}
.events-mobile .events .event-item .icons .fb a,
.right-hover-column-body .events .event-item .icons .fb a {
  color: #3040a2;
}
.events-mobile .events .event-item .icons .other,
.right-hover-column-body .events .event-item .icons .other {
  margin-left: 17px;
}
.events-mobile .events .event-item .icons .other a,
.right-hover-column-body .events .event-item .icons .other a {
  color: #3040a2;
}
.events-mobile .events .event-item .icons .map,
.right-hover-column-body .events .event-item .icons .map {
  margin-left: 20px;
}
.events-mobile .events .event-item .icons .map a,
.right-hover-column-body .events .event-item .icons .map a {
  color: #fec436;
}
.events-mobile .events .event-item:last-child,
.right-hover-column-body .events .event-item:last-child {
  border-bottom: 0px dashed #878787;
}
.events-mobile,
.goldance-events {
  padding: 15px;
}
.events-mobile .event-item,
.goldance-events .event-item {
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #878787;
}
.events-mobile .event-item .info,
.goldance-events .event-item .info {
  width: 80%;
  display: inline-block;
}
.events-mobile .event-item .info .datetime,
.goldance-events .event-item .info .datetime {
  font-size: 18px;
  color: #000;
  font-family: "Comfortaa", cursive;
}
.events-mobile .event-item .info .datetime span,
.goldance-events .event-item .info .datetime span {
  color: #878787;
}
.events-mobile .event-item .info .city,
.goldance-events .event-item .info .city {
  font-weight: bold;
  font-size: 20px;
}
.events-mobile .event-item .info .address,
.goldance-events .event-item .info .address {
  font-size: 14px;
}
.events-mobile .event-item .icons,
.goldance-events .event-item .icons {
  width: 19%;
  display: inline-block;
  text-align: center;
}
.events-mobile .event-item .icons .fb,
.goldance-events .event-item .icons .fb {
  display: inline-block;
  font-size: 9px;
}
.events-mobile .event-item .icons .fb a,
.goldance-events .event-item .icons .fb a {
  color: #3040a2;
  font-size: 30px;
}
.events-mobile .event-item .icons .other,
.goldance-events .event-item .icons .other {
  display: inline-block;
  font-size: 9px;
}
.events-mobile .event-item .icons .other a,
.goldance-events .event-item .icons .other a {
  color: #3040a2;
  font-size: 30px;
}
.events-mobile .event-item .icons .map,
.goldance-events .event-item .icons .map {
  display: inline-block;
  margin-left: 20px;
  font-size: 9px;
}
.events-mobile .event-item .icons .map a,
.goldance-events .event-item .icons .map a {
  color: #ffc536;
  font-size: 30px;
}
.events-mobile .event-item .description,
.goldance-events .event-item .description {
  position: relative;
}
.events-mobile .event-item .description h4,
.goldance-events .event-item .description h4 {
  padding-bottom: 0px;
}
.events-mobile .event-item .description a.event-link,
.goldance-events .event-item .description a.event-link {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 100;
}
.events-mobile .event-item .description .event-img,
.goldance-events .event-item .description .event-img {
  height: 150px;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('/template/images/img-esemeny.png');
}
/*
  Application fellepesek styles
*/
.faq-item {
  margin: 15px 0px 0px 0px;
}
.faq-item .title a {
  font-size: 16px;
  color: #666;
  text-decoration: none;
}
.faq-item .title a:hover {
  font-size: 16px;
  color: #ffc536;
  text-decoration: none;
  font-weight: bold;
}
.faq-item .title a.opened {
  font-weight: bold;
  color: #000;
  text-decoration: none;
}
.faq-item .description {
  display: none;
  padding-left: 20px;
  font-size: 13px;
}
/*
  Application locations styles
*/
.locationblock .error {
  font-size: 11px;
  color: #aa0000;
}
.goldance-location .picture {
  text-align: center;
}
.goldance-location .picture img {
  width: 100%;
}
.goldance-location .picture .fa {
  font-size: 100px;
  opacity: 0.3;
}
.location-list li {
  margin-top: 0px;
  padding: 5px;
  transition: all 0.4s;
}
.location-list li i {
  color: #ff0000;
}
.location-list li span {
  font-size: 11px;
}
.location-list li i.fa-chevron-down,
.location-list li i.fa-chevron-up {
  cursor: pointer;
}
.location-list li .list {
  line-height: 14px;
}
.location-list li .description {
  opacity: 0;
  height: 0px;
  padding-top: 10px;
  font-size: 10px;
  transition: all 0.3s;
  user-select: none;
}
.location-list li .description .img {
  font-size: 40px;
}
.location-list li .description .img i {
  opacity: 0.2;
}
.location-list li .btn {
  padding: 5px 20px;
}
.location-list li.opened {
  background: #fff;
}
.location-list li.opened .description {
  display: block;
  opacity: 1;
  height: auto;
}
.locationsMap {
  position: relative;
  height: 450px;
}
.locationsMap #locationsMapLoader {
  top: 0px;
  left: 0px;
  position: absolute;
  display: block;
  width: 100%;
  height: 450px;
  z-index: 1000;
  text-align: center;
  padding-top: 100px;
  font-size: 40px;
}
.locationsMap #locationsMap {
  height: 450px;
  width: 100%;
  visibility: hidden;
}
.location-item {
  padding: 5px;
}
.location-item .inner {
  position: relative;
  margin: 10px;
  height: 300px;
  width: 100%;
  background-image: url('../images/location-pic-holder.png');
  background-position: center center;
  background-size: cover;
  padding: 0px;
}
.location-item .inner .description {
  display: none;
  width: 350px;
  height: 300px;
  border: 2px solid #e2e2e2;
  line-height: 16px;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 14px;
  margin-top: 15px;
  font-weight: 100;
}
.location-item .inner .data {
  display: none;
  padding: 10px;
  height: 300px;
  width: 100%;
  background-color: rgba(230, 230, 230, 0.9);
}
.location-item .inner .data .info {
  overflow-y: scroll;
  height: 230px;
}
.location-item .inner .data .info h4 {
  width: 100%;
  font-size: 14px;
  color: #494949;
  font-family: "Comfortaa", cursive;
}
.location-item .inner .data .info ul.location-details {
  width: 100%;
  height: 200px;
  padding-left: 0px;
  margin-left: 0px;
}
.location-item .inner .data .info ul.location-details .label {
  font-size: 12px;
  color: #494949;
  font-family: "Comfortaa", cursive;
  display: inline-block;
  width: 38%;
  text-align: left;
  vertical-align: top;
}
.location-item .inner .data .info ul.location-details .value {
  font-size: 12px;
  color: #494949;
  font-weight: 300;
  font-family: "Comfortaa", cursive;
  display: inline-block;
  width: 60%;
  text-align: left;
  vertical-align: top;
}
.location-item .inner .data .info ul.location-details .value p {
  padding: 0px 0px;
  margin: 0px 0px 2px 0px;
}
.location-item .inner .data .more {
  padding-top: 10px;
  height: 30px;
}
.location-item.bottomborder {
  border-bottom: 1px dashed #666;
}
/*
  Application fellepesek styles
*/
.gallery-cat .gallery-cat-item {
  width: 100%;
  height: 100%;
  display: block;
  padding: 10px;
}
.gallery-cat .gallery-cat-item a {
  font-family: "Comfortaa", cursive;
  width: 100%;
  height: 100%;
  display: block;
  text-decoration: none;
  text-align: center;
}
.gallery-cat .gallery-cat-item a figure {
  width: 100%;
  height: 150px;
  background-size: cover;
  background-position: center center;
  border-radius: 10px;
  margin: 10px 0px;
}
.gallery-cat .gallery-cat-item a small {
  font-size: 12px;
  color: #aaa;
  display: block;
}
.gallery-cat .gallery-cat-item a span.title {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  display: block;
}
.gallery-cat .gallery-cat-item a span.updated {
  color: #999;
  font-size: 14px;
  display: block;
}
.gallery-cat .gallery-cat-item a:hover {
  text-decoration: none;
}
.gallery-cat .gallery-cat-item a:hover figure {
  -webkit-box-shadow: inset 0px 0px 34px 10px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: inset 0px 0px 34px 10px rgba(0, 0, 0, 0.6);
  box-shadow: inset 0px 0px 34px 10px rgba(0, 0, 0, 0.6);
}
.gallery-subcat .gallery-subcat-item {
  width: 100%;
  height: 100%;
  display: block;
  padding: 10px;
}
.gallery-subcat .gallery-subcat-item a {
  font-family: "Comfortaa", cursive;
  width: 100%;
  height: 100%;
  display: block;
  text-decoration: none;
  text-align: center;
}
.gallery-subcat .gallery-subcat-item a figure {
  width: 100%;
  height: 150px;
  background-size: cover;
  background-position: center center;
  border-radius: 10px;
  margin: 10px 0px;
}
.gallery-subcat .gallery-subcat-item a span.title {
  font-size: 18px;
  color: #333;
  font-weight: bold;
  display: block;
}
.gallery-subcat .gallery-subcat-item a span.subtitle {
  color: #999;
  font-size: 14px;
  display: block;
}
.gallery-subcat .gallery-subcat-item a:hover {
  text-decoration: none;
}
.gallery-subcat .gallery-subcat-item a:hover figure {
  -webkit-box-shadow: inset 0px 0px 34px 10px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: inset 0px 0px 34px 10px rgba(0, 0, 0, 0.6);
  box-shadow: inset 0px 0px 34px 10px rgba(0, 0, 0, 0.6);
}
.gallery-images .col-lg-1,
.gallery-images .col-lg-10,
.gallery-images .col-lg-11,
.gallery-images .col-lg-12,
.gallery-images .col-lg-2,
.gallery-images .col-lg-3,
.gallery-images .col-lg-4,
.gallery-images .col-lg-5,
.gallery-images .col-lg-6,
.gallery-images .col-lg-7,
.gallery-images .col-lg-8,
.gallery-images .col-lg-9,
.gallery-images .col-md-1,
.gallery-images .col-md-10,
.gallery-images .col-md-11,
.gallery-images .col-md-12,
.gallery-images .col-md-2,
.gallery-images .col-md-3,
.gallery-images .col-md-4,
.gallery-images .col-md-5,
.gallery-images .col-md-6,
.gallery-images .col-md-7,
.gallery-images .col-md-8,
.gallery-images .col-md-9,
.gallery-images .col-sm-1,
.gallery-images .col-sm-10,
.gallery-images .col-sm-11,
.gallery-images .col-sm-12,
.gallery-images .col-sm-2,
.gallery-images .col-sm-3,
.gallery-images .col-sm-4,
.gallery-images .col-sm-5,
.gallery-images .col-sm-6,
.gallery-images .col-sm-7,
.gallery-images .col-sm-8,
.gallery-images .col-sm-9,
.gallery-images .col-xs-1,
.gallery-images .col-xs-10,
.gallery-images .col-xs-11,
.gallery-images .col-xs-12,
.gallery-images .col-xs-2,
.gallery-images .col-xs-3,
.gallery-images .col-xs-4,
.gallery-images .col-xs-5,
.gallery-images .col-xs-6,
.gallery-images .col-xs-7,
.gallery-images .col-xs-8,
.gallery-images .col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
}
.gallery-images a.gallery-image-item {
  font-family: "Comfortaa", cursive;
  width: 70px;
  height: 80px;
  display: inline-block;
  text-decoration: none;
  text-align: center;
  margin-top: 5px;
  margin-left: 5px;
}
.gallery-images a.gallery-image-item figure,
.gallery-images a.gallery-image-item img {
  width: 100%;
  height: 80px;
  background-size: cover;
  background-position: center center;
  border-radius: 10px;
  margin: 5px 0px;
}
.gallery-images a:hover {
  text-decoration: none;
}
.gallery-images a:hover figure {
  -webkit-box-shadow: inset 0px 0px 34px 10px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: inset 0px 0px 34px 10px rgba(0, 0, 0, 0.6);
  box-shadow: inset 0px 0px 34px 10px rgba(0, 0, 0, 0.6);
}
.lg-outer .lg-has-vimeo .lg-video-play {
  background: url("../images/vimeo-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-vimeo:hover .lg-video-play {
  background: url("../images/vimeo-play.png") no-repeat scroll 0 -58px transparent;
}
.lg-outer .lg-has-html5 .lg-video-play {
  background: transparent url("../images/video-play.png") no-repeat scroll 0 0;
  height: 64px;
  margin-left: -32px;
  margin-top: -32px;
  width: 64px;
  opacity: 0.8;
}
.lg-outer .lg-has-html5:hover .lg-video-play {
  opacity: 1;
}
.lg-outer .lg-has-youtube .lg-video-play {
  background: url("../images/youtube-play.png") no-repeat scroll 0 0 transparent;
}
.lg-outer .lg-has-youtube:hover .lg-video-play {
  background: url("../images/youtube-play.png") no-repeat scroll 0 -60px transparent;
}
/*
  Application dancepages styles
*/
.dancepage-hero {
  width: 100%;
  height: 300px;
  position: relative;
  background-color: #000;
  background-position: center;
}
.dancepage-hero .top-shade {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='100,0 0,100 0, 0' style='fill:#f2f2f2' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: '';
  display: block;
  height: 100px;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0px;
}
.dancepage-hero .bottom-shade {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='0,100 100,0 100,100' style='fill:#f2f2f2' /%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: '';
  display: block;
  height: 100px;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0px;
}
.dancepage-hero .highlight {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+47&1+0,0+46 */
  background: -moz-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 7%);
  /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 7%);
  /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 70%, rgba(255, 255, 255, 0) 7%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#00ffffff', GradientType=1);
  /* IE6-9 fallback on horizontal gradient */
  width: 100%;
  height: 385px;
  display: block;
  z-index: 15;
}
.dancepage.swing .dancepage-hero,
.dancepage.west-coast-swing .dancepage-hero {
  background: none;
  background-image: url('/images/subpagehero-title-swing.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.dancepage.eskuvoi .dancepage-hero {
  background: none;
  background-image: url('/images/subpagehero-title-eskuvo.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.dancepage.tarsastanc .dancepage-hero {
  background: none;
  background-image: url('/images/subpagehero-title-tarsas.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.dancepage h4 {
  margin: 0px;
  padding: 20px 0px 10px;
}
.dancepage .timetable-item-border {
  border-bottom: 1px dashed #ddd;
  padding: 10px;
}
.dancepage .locationsMap {
  height: auto;
}
.dancepage.eskuvoi .dance-item {
  padding: 20px;
  color: #5e5e5e;
}
.dancepage.eskuvoi .dance-item .image {
  background-size: contain;
  width: 100%;
  height: 135px;
  background-position: center;
  position: relative;
}
.dancepage.eskuvoi .dance-item .image .btn_play {
  position: absolute;
  width: 100%;
  height: 100%;
  color: #000;
  opacity: 0;
  font-size: 80px;
  text-align: center;
  padding-top: 30px;
  -webkit-transition: all 1s;
  /* Safari */
  transition: all 1s;
}
.dancepage.eskuvoi .dance-item .image:hover .btn_play {
  opacity: 0.8;
}
.dancepage.eskuvoi .dance-item .title {
  font-size: 20px;
  font-family: "Comfortaa", cursive;
  text-align: center;
  width: 100%;
}
.dancepage.eskuvoi .dance-item .descr {
  font-size: 14px;
  text-align: center;
  width: 100%;
}
.dancepage.szalagavato .dance-item {
  padding: 20px;
  color: #5e5e5e;
  height: 320px;
}
.dancepage.szalagavato .dance-item .image {
  background-size: contain;
  width: 100%;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.dancepage.szalagavato .dance-item .image .btn_play {
  position: absolute;
  width: 100%;
  height: 100%;
  color: #fff;
  opacity: 0;
  font-size: 80px;
  text-align: center;
  padding-top: 40px;
  -webkit-transition: all 1s;
  /* Safari */
  transition: all 1s;
}
.dancepage.szalagavato .dance-item .image:hover .btn_play {
  opacity: 0.8;
}
.dancepage.szalagavato .dance-item .title {
  font-size: 20px;
  font-family: "Comfortaa", cursive;
  text-align: center;
  width: 100%;
}
.dancepage.szalagavato .dance-item .descr {
  font-size: 14px;
  text-align: center;
  width: 100%;
}
/*
  Application dancepages styles
*/
.dancepage .blocktitle i {
  font-size: 34px;
  color: #ffc536;
  padding: 20px 0px;
}
.dancepage .white hr {
  height: 1px;
  border: 0px;
  border-bottom: 1px dashed #aaa;
}
.dancepage .grey hr {
  height: 1px;
  border: 0px;
  border-bottom: 1px dashed #666;
}
.dancepage .main-image {
  width: 100%;
  height: 530px;
  position: absolute;
  top: -300px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.dancepage .dancepage-nav {
  width: 100%;
  height: 400px;
  background: #F2F2F2;
  position: relative;
}
.dancepage .dancepage-nav .col-md-12 {
  height: 400px;
}
.dancepage .dancepage-nav .dancetype-lead {
  width: 100%;
  height: 130px;
  position: absolute;
  left: 0px;
  bottom: 50px;
}
.dancepage .dancepage-nav .dancetype-lead .dancetype-title {
  font-family: "Comfortaa", cursive;
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #969696;
}
.dancepage .dancepage-nav h1 {
  color: #5e5e5e;
}
.dancepage .dancepage-nav h1 i {
  font-size: 28px;
  color: #000;
}
.dancepage .dancepage-nav nav {
  width: 100%;
  height: 50px;
  position: absolute;
  left: 0px;
  bottom: -10px;
  text-align: center;
  z-index: 25;
}
.dancepage .dancepage-nav nav ul {
  width: 100%;
  display: inline-block;
  list-style-type: none;
}
.dancepage .dancepage-nav nav ul li {
  width: auto;
  height: 50px;
  display: inline-block;
  position: relative;
}
.dancepage .dancepage-nav nav ul li a {
  border-top: 1px solid #fff;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  padding: 10px 20px 5px 20px;
  color: #000;
  font-size: 17px;
  font-family: "Comfortaa", cursive;
  font-weight: 300;
  text-decoration: none;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background: #fff;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 40;
}
.dancepage .dancepage-nav nav ul li a i {
  color: #ccc;
  margin-right: 10px;
}
.dancepage .dancepage-nav nav ul li a:hover {
  /*        background:#ffc536;
            border-color: #ffc536;;
            color:#fff;*/
}
.dancepage .dancepage-nav nav ul li a:hover i {
  color: #000;
  /*color:#fff;*/
}
/*
 DancePage unique stylings!
 */
/*
  Zumba styles
*/
.dancepage.zumba .main-image {
  background-image: url('/images/subpagehero-img-salsa.png');
}
.dancepage.zumba .blocktitle i {
  color: #28a372;
}
.dancepage.zumba nav ul li a i {
  color: #28a372;
}
.dancepage.zumba nav ul li a:hover {
  color: #28a372;
}
.dancepage.zumba nav ul li a:hover i {
  color: #28a372;
}
.dancepage.zumba .btn-yellow {
  background: #28a372;
}
.dancepage.zumba .btn.btn-yellow-inverz {
  border-color: #28a372;
  background-color: #fff;
  color: #28a372;
}
/*
  Tarsas styles
*/
.dancepage.tarsastanc .main-image {
  background-image: url('/images/subpagehero-img-tarsas.png');
}
.dancepage.tarsastanc .blocktitle i {
  color: #ff6c00;
}
.dancepage.tarsastanc nav ul li a i {
  color: #ff6c00;
}
.dancepage.tarsastanc nav ul li a:hover {
  color: #ff6c00;
}
.dancepage.tarsastanc nav ul li a:hover i {
  color: #ff6c00;
}
.dancepage.tarsastanc .btn-yellow {
  background: #ff6c00;
}
.dancepage.tarsastanc .btn.btn-yellow-inverz {
  border-color: #ff6c00;
  background-color: #fff;
  color: #ff6c00;
}
/*
  Salsa styles
*/
.dancepage.salsa .main-image {
  background-image: url('/images/subpagehero-img-salsa.png');
}
.dancepage.salsa .blocktitle i {
  color: #ac0302;
}
.dancepage.salsa nav ul li a i {
  color: #ac0302;
}
.dancepage.salsa nav ul li a:hover {
  color: #ac0302;
}
.dancepage.salsa nav ul li a:hover i {
  color: #ac0302;
}
.dancepage.salsa .btn-yellow {
  background: #ac0302;
}
.dancepage.salsa .btn.btn-yellow-inverz {
  border-color: #ac0302;
  background-color: #fff;
  color: #ac0302;
}
/*
  Swing styles
*/
.dancepage.swing .main-image,
.dancepage.west-coast-swing .main-image {
  background-image: url('/images/subpagehero-img-swing.png');
}
.dancepage.swing .blocktitle i,
.dancepage.west-coast-swing .blocktitle i {
  color: #ffc536;
}
.dancepage.swing nav ul li a i,
.dancepage.west-coast-swing nav ul li a i {
  color: #ffc536;
}
.dancepage.swing nav ul li a:hover,
.dancepage.west-coast-swing nav ul li a:hover {
  color: #ffc536;
}
.dancepage.swing nav ul li a:hover i,
.dancepage.west-coast-swing nav ul li a:hover i {
  color: #ffc536;
}
.dancepage.swing .btn-yellow,
.dancepage.west-coast-swing .btn-yellow {
  background: #ffc536;
}
.dancepage.swing .btn.btn-yellow-inverz,
.dancepage.west-coast-swing .btn.btn-yellow-inverz {
  border-color: #ffc536;
  background-color: #fff;
  color: #ffc536;
}
/*
  Rocky styles
*/
.dancepage.rocky .main-image {
  background-image: url('/images/subpagehero-img-rocky.png');
}
.dancepage.rocky .blocktitle i {
  color: #00AEFF;
}
.dancepage.rocky nav ul li a i {
  color: #00AEFF;
}
.dancepage.rocky nav ul li a:hover {
  color: #00AEFF;
}
.dancepage.rocky nav ul li a:hover i {
  color: #00AEFF;
}
.dancepage.rocky .btn-yellow {
  background: #00AEFF;
}
.dancepage.rocky .btn.btn-yellow-inverz {
  border-color: #00AEFF;
  background-color: #fff;
  color: #00AEFF;
}
/*
  Bachata styles
*/
.dancepage.bachata .main-image {
  background-image: url('/images/subpagehero-img-bachata.png');
}
.dancepage.bachata .blocktitle i {
  color: #b732d9;
}
.dancepage.bachata nav ul li a i {
  color: #b732d9;
}
.dancepage.bachata nav ul li a:hover {
  color: #b732d9;
}
.dancepage.bachata nav ul li a:hover i {
  color: #b732d9;
}
.dancepage.bachata .btn-yellow {
  background: #b732d9;
}
.dancepage.bachata .btn.btn-yellow-inverz {
  border-color: #b732d9;
  background-color: #fff;
  color: #b732d9;
}
/*
  Fellépés styles
*/
.dancepage.fellepes .main-image {
  background-image: url('/images/subpagehero-img-fellepes.png');
}
.dancepage.fellepes .blocktitle i {
  color: #e5d5a0;
}
.dancepage.fellepes nav ul li a i {
  color: #e5d5a0;
}
.dancepage.fellepes nav ul li a:hover {
  color: #e5d5a0;
}
.dancepage.fellepes nav ul li a:hover i {
  color: #e5d5a0;
}
.dancepage.fellepes .btn-yellow {
  background: #e5d5a0;
}
.dancepage.fellepes .btn.btn-yellow-inverz {
  border-color: #e5d5a0;
  background-color: #fff;
  color: #e5d5a0;
}
/*
  Szalagavato styles
*/
.dancepage.szalagavato .main-image {
  background-image: url('/images/subpagehero-img-szalagavato.png');
}
.dancepage.szalagavato .blocktitle i {
  color: #bbccd9;
}
.dancepage.szalagavato nav ul li a i {
  color: #bbccd9;
}
.dancepage.szalagavato nav ul li a:hover {
  color: #bbccd9;
}
.dancepage.szalagavato nav ul li a:hover i {
  color: #bbccd9;
}
.dancepage.szalagavato .btn-yellow {
  background: #84aac4;
}
.dancepage.szalagavato .btn.btn-yellow-inverz {
  border-color: #bbccd9;
  background-color: #fff;
  color: #bbccd9;
}
/*
  Eskuvoi styles
*/
.dancepage.eskuvoi .main-image {
  background-image: url('/images/subpagehero-img-eskuvo.png');
}
.dancepage.eskuvoi .blocktitle i {
  color: #cfdde5;
}
.dancepage.eskuvoi nav ul li a i {
  color: #cfdde5;
}
.dancepage.eskuvoi nav ul li a:hover {
  color: #cfdde5;
}
.dancepage.eskuvoi nav ul li a:hover i {
  color: #cfdde5;
}
.dancepage.eskuvoi .btn-yellow {
  background: #84aac4;
}
.dancepage.eskuvoi .btn.btn-yellow-inverz {
  border-color: #cfdde5;
  background-color: #fff;
  color: #cfdde5;
}
/*
  Application start page right hover style
*/
.right-hover-column {
  position: absolute;
  right: 40px;
  top: 530px;
  width: 260px;
  z-index: 30;
}
.right-hover-column-head {
  width: 100%;
  height: auto;
  margin-top: -10px;
  min-height: 55px;
  background: url('../images/banner-right-top.png');
  background-repeat: no-repeat;
  transform: rotate(-7deg);
}
.right-hover-column-body {
  background: #FCFCFC;
  width: 249px;
  margin: -37px 0px 0px 6px;
  padding: 20px 10px 10px 10px;
  min-height: 300px;
  -webkit-box-shadow: 0px 5px 23px -5px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 5px 23px -5px rgba(0, 0, 0, 0.57);
  box-shadow: 0px 5px 23px -5px rgba(0, 0, 0, 0.57);
}
/*
  Notification
*/
.notification {
  position: fixed;
  bottom: 0px;
  right: 0px;
  background: #bc40db;
  color: #ffffff;
  text-align: center;
  width: 100%;
  padding: 10px;
  z-index: 1000;
}
.notification i.fa-bullhorn {
  font-size: 22px;
  font-weight: bold;
  display: block;
  position: absolute;
  top: 18px;
  left: 20px;
  cursor: pointer;
}
.notification i.fa-close {
  font-size: 18px;
  font-weight: normal;
  display: block;
  position: absolute;
  top: 18px;
  right: 20px;
  cursor: pointer;
}
.notification .btn {
  background: #ffffff;
  color: #bc40db;
  margin-left: 20px;
}
.notification span {
  display: inline-block;
}
.notification.minimize-notif {
  width: 54px;
  height: 54px;
}
.notification.minimize-notif span {
  display: none;
}
.notification.minimize-notif i.fa-close {
  display: none;
}
/*
  Application - Free first lesson reg application
*/
#firstlessonRegModal .modal-dialog {
  margin-top: 210px;
}
#firstlessonRegModal .modal-dialog .modal-content {
  position: relative;
}
#firstlessonRegModal .modal-dialog .modal-content .modal-topdecor {
  width: 100%;
  height: 180px;
  top: -180px;
  left: 0px;
  position: absolute;
  background-image: url('../images/ingyenes-ora/modal-header.png');
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
}
#firstlessonRegModal .modal-dialog .modal-content .modal-body {
  background: #f6f6f6;
}
#firstlessonRegModal .modal-dialog .modal-content .modal-body .selectric-wrapper .selectric {
  height: 45px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}
#firstlessonRegModal .modal-dialog .modal-content .modal-body .selectric-wrapper .selectric .label {
  height: 45px;
  line-height: 45px;
  font-family: "Comfortaa", cursive;
}
#firstlessonRegModal .modal-dialog .modal-content .modal-body .selectric-wrapper .selectric .button {
  height: 43px;
  line-height: 43px;
}
#firstlessonRegModal .modal-dialog .modal-content .modal-footer {
  text-align: left;
}
#firstlessonRegModal .modal-dialog .modal-content .modal-footer .form-group input[type="text"],
#firstlessonRegModal .modal-dialog .modal-content .modal-body .form-group input[type="text"] {
  height: 45px;
  font-family: "Comfortaa", cursive;
}
#firstlessonRegModal .modal-dialog .modal-content .modal-footer .form-group .col-sm-4,
#firstlessonRegModal .modal-dialog .modal-content .modal-body .form-group .col-sm-4 {
  padding-right: 5px;
  padding-left: 5px;
}
#firstLessonApp {
  position: relative;
}
#firstLessonApp .firstlessonreg-load-placeholder {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding-top: 150px;
  text-align: center;
  font-size: 60px;
  color: #ddd;
  background: #eee;
}
/*
  Media queries
*/
/* Small Devices, Tablets */
/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  #firstlessonRegModal .modal-dialog {
    margin-top: 160px;
  }
  #firstlessonRegModal .form-group input {
    text-align: left !important;
  }
  #firstlessonRegModal .btn.btn-yellow,
  #firstlessonRegModal .btn.btn-yellow-inverz {
    width: 100%;
    margin: 0px;
    margin-top: 10px;
  }
}
/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
  #firstlessonRegModal .modal-dialog {
    margin-top: 160px;
  }
  #firstlessonRegModal .form-group input {
    text-align: left !important;
  }
}
/*
  Application - new lesson reg application
*/
#newlessonRegModal .modal-dialog {
  margin-top: 210px;
}
#newlessonRegModal .modal-dialog .modal-content {
  position: relative;
}
#newlessonRegModal .modal-dialog .modal-content .modal-topdecor {
  width: 100%;
  height: 180px;
  top: -180px;
  left: 0px;
  position: absolute;
  background-image: url('../images/ingyenes-ora/modal-header.png');
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
}
#newlessonRegModal .modal-dialog .modal-content .modal-body {
  background: #f6f6f6;
}
#newlessonRegModal .modal-dialog .modal-content .modal-body .selectric-wrapper .selectric {
  height: 45px;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
}
#newlessonRegModal .modal-dialog .modal-content .modal-body .selectric-wrapper .selectric .label {
  height: 45px;
  line-height: 45px;
  font-family: "Comfortaa", cursive;
}
#newlessonRegModal .modal-dialog .modal-content .modal-body .selectric-wrapper .selectric .button {
  height: 43px;
  line-height: 43px;
}
#newlessonRegModal .modal-dialog .modal-content .modal-footer {
  text-align: left;
}
#newlessonRegModal .modal-dialog .modal-content .modal-footer .form-group input[type="text"],
#newlessonRegModal .modal-dialog .modal-content .modal-body .form-group input[type="text"] {
  height: 45px;
  font-family: "Comfortaa", cursive;
}
#newlessonRegModal .modal-dialog .modal-content .modal-footer .form-group .col-sm-4,
#newlessonRegModal .modal-dialog .modal-content .modal-body .form-group .col-sm-4 {
  padding-right: 5px;
  padding-left: 5px;
}
#newLessonApp {
  position: relative;
}
#newLessonApp .newlessonreg-load-placeholder {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding-top: 150px;
  text-align: center;
  font-size: 60px;
  color: #ddd;
  background: #eee;
}
/*
  Media queries
*/
/* Small Devices, Tablets */
/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  #newlessonRegModal .modal-dialog {
    margin-top: 160px;
  }
  #newlessonRegModal .form-group input {
    text-align: left !important;
  }
  #newlessonRegModal .btn.btn-yellow,
  #newlessonRegModal .btn.btn-yellow-inverz {
    width: 100%;
    margin: 0px;
    margin-top: 10px;
  }
}
/* Custom, iPhone Retina */
@media only screen and (max-width: 320px) {
  #newlessonRegModal .modal-dialog {
    margin-top: 160px;
  }
  #newlessonRegModal .form-group input {
    text-align: left !important;
  }
}
/*
  Goldance responsive media queries
*/
@media all and (min-width: 1000px) {
  iframe {
    min-height: 300px;
  }
  .hero {
    display: block;
  }
  .hero-mobile {
    display: none;
  }
  .mobile {
    display: inline-block !important;
    visibility: hidden;
    height: 0px;
    width: 0px;
  }
  .nomobile {
    display: block !important;
  }
}
@media all and (max-width: 992px) and (min-width: 768px) {
  iframe {
    min-height: 300px;
  }
  .right-hover-column {
    display: none;
  }
  .events-mobile {
    display: block;
  }
  .site-top-container {
    padding-top: 130px;
  }
  .start-here img {
    width: 100%;
    height: auto;
  }
  .hero {
    display: none;
  }
  .hero-mobile {
    display: block;
  }
  .nomobile {
    display: block !important;
  }
  .mobile {
    display: inline-block !important;
    visibility: hidden;
    height: 0px;
    width: 0px;
  }
}
/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  iframe {
    min-height: 300px;
  }
  .right-hover-column {
    display: none;
  }
  .events-mobile {
    display: block;
  }
  .site-top-container {
    padding-top: 130px;
  }
  .start-here {
    margin-top: 0px;
    text-align: center;
    height: auto;
    padding: 20px 0px;
  }
  .start-here img {
    width: 100%;
    height: auto;
  }
  .highlight-services {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .highlight-banner {
    height: 150px;
  }
  .hero {
    display: none;
  }
  .hero-mobile {
    display: block;
  }
  .hero-mobile .carousel-inner > .item > a {
    display: block;
    text-align: center;
  }
  .hero-mobile .carousel-inner > .item > a > img,
  .hero-mobile .carousel-inner > .item > img {
    height: 500px;
    z-index: 150;
    display: inline-block;
  }
  .hero-mobile .carousel-control.right {
    background-image: none;
  }
  .hero-mobile .carousel-control.left {
    background-image: none;
  }
  .hero-mobile .glyphicon-chevron-right:before {
    background: #aaa;
  }
  .hero-mobile .glyphicon-chevron-left:before {
    background: #aaa;
  }
  .hero-mobile .carousel-caption {
    font-size: 20px;
  }
  .hero-mobile .carousel-indicators li {
    width: 20px;
    height: 20px;
    border: 3px solid #fff;
  }
  .btn-yellow {
    font-size: 14px;
  }
  footer nav {
    text-align: center;
    margin-bottom: 20px;
  }
  footer .goldance-logo {
    background-position: center;
    max-width: 100%;
  }
  .site-top .popup-navigation .nav-head .btn-cont {
    width: 50%;
  }
  .subhero nav {
    display: none;
  }
  .popup-navigation {
    height: 310px;
    overflow: hidden;
  }
  .popup-navigation .nav-body {
    max-height: 180px;
    overflow-y: scroll;
  }
  .nomobile {
    display: none !important;
  }
  .mobile {
    display: inline-block !important;
    visibility: hidden;
    height: 0px;
    width: 0px;
  }
}
@media only screen and (max-width: 768px) and (orientation: landscape) {
  iframe {
    min-height: 300px;
  }
  .site-top.scrolled {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .events-mobile {
    display: block;
  }
  .site-top-container {
    padding-top: 130px;
  }
  .site-top .logo-container {
    height: 40px;
  }
  .site-top .logo-container .logo img {
    height: 35px;
  }
  .site-top .logo-container .info {
    padding-top: 5px;
    height: 40px;
  }
  .popup-navigation {
    height: 310px;
    overflow: hidden;
  }
  .popup-navigation .nav-body {
    max-height: 180px;
    overflow-y: scroll;
  }
  .service-item {
    text-align: center;
  }
  .nomobile {
    display: none !important;
  }
  .mobile {
    display: block !important;
  }
  .nomobile {
    display: none !important;
  }
  .mobile {
    display: inline-block !important;
    visibility: visible;
    height: auto;
    width: auto;
  }
  .firstlesson-head-mobile {
    display: block !important;
  }
}
/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  /* Force table to not be like tables anymore */
  table,
  thead,
  tbody,
  th,
  td,
  tr {
    display: block;
  }
  /* Hide table headers (but not display: none;, for accessibility) */
  thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  tr {
    margin: 0 0 1rem 0;
    border-top: 2px solid #aaa;
  }
  tr.child {
    margin: 0 0 1rem 0;
    border-top: 0px;
  }
  tr:nth-child(odd) {
    background: #ccc;
  }
  td {
    /* Behave  like a "row" */
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
  }
  td:before {
    /* Now like a table header */
    position: absolute;
    /* Top/left values mimic padding */
    top: 0;
    left: 6px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
  }
  iframe {
    min-height: auto;
  }
  .mobile-col-head {
    display: inline;
    font-weight: bold;
  }
  .events-mobile {
    display: block;
  }
  .events-mobile .banner-item img {
    max-width: 100%;
    width: 100%;
  }
  .popup-nav-container .popup-navigation {
    margin-top: -110px;
    height: 630px;
  }
  .popup-nav-container .popup-navigation .nav-head .btn-cont .facebook,
  .popup-nav-container .popup-navigation .nav-head .btn-cont .map {
    display: none;
  }
  .popup-nav-container .popup-navigation .nav-head .btn-cont a {
    font-size: 32px;
  }
  .popup-nav-container .popup-navigation .nav-body {
    max-height: 80% !important;
  }
  .site-top-container {
    padding-top: 130px;
  }
  .site-top-container .logo-container .info {
    display: none;
  }
  .site-top-container .covid19 {
    text-align: right;
    padding: 5px 10px 0px 10px;
  }
  .site-top-container .covid19 img {
    max-height: 15px;
  }
  .site-top .logo-container {
    height: 40px;
  }
  .site-top .logo-container .logo img {
    height: 35px;
  }
  .social-link-container {
    display: none;
  }
  .popupnav-social-container {
    display: block !important;
  }
  .popup-nav-container .popup-navigation .nav-head .btn-cont {
    width: 50%;
  }
  .nav-body {
    max-height: 500px;
    overflow-y: scroll;
  }
  .nav-body .mcollapse .title {
    position: relative;
  }
  .nav-body .mcollapse .title .fa {
    position: absolute;
    right: 20px;
  }
  .nav-body .mcollapse ul {
    display: none;
  }
  .hero-mobile .carousel-inner > .item > a {
    display: block;
    text-align: center;
  }
  .hero-mobile .carousel-inner > .item > a > img,
  .hero-mobile .carousel-inner > .item > img {
    height: 350px;
    z-index: 150;
    display: inline-block;
  }
  .dancepage .dancepage-nav {
    height: auto;
  }
  .dancepage .dancepage-nav nav {
    position: relative;
  }
  .dancepage .dancepage-nav nav ul {
    padding: 0px;
    margin: 0px;
  }
  .dancepage .dancepage-nav nav ul li {
    display: block;
    width: 100%;
  }
  .dancepage .dancepage-nav nav ul li a {
    width: 100%;
  }
  .dancepage .main-image {
    top: -380px;
    max-width: 95%;
  }
  iframe {
    width: 90%;
  }
  .content img {
    max-width: 100%;
    height: auto;
  }
  .goldance-events .event-item .icons {
    width: 95%;
  }
  .goldance-events .event-item .icons span {
    display: none;
  }
  .service-item img {
    width: 100%;
  }
  .service-item + .service-item {
    margin-top: 20px;
  }
  .nomobile {
    display: none !important;
  }
  .mobile {
    display: inline-block !important;
    visibility: visible;
    height: auto;
    width: auto;
  }
  .firstlesson-head-mobile {
    display: block !important;
  }
}
/* Custom, iPhone Retina */
@media only screen and (max-width: 321px) {
  iframe {
    min-height: auto;
  }
  .events-mobile {
    display: block;
  }
  .events-mobile .banner-item img {
    max-width: 100% !important;
    width: 100%;
  }
  .hero-mobile .carousel-inner > .item > a {
    display: block;
    text-align: center;
  }
  .hero-mobile .carousel-inner > .item > a > img,
  .hero-mobile .carousel-inner > .item > img {
    height: 300px;
    z-index: 150;
    display: inline-block;
  }
  .nomobile {
    display: none !important;
  }
  .mobile {
    display: inline-block !important;
    visibility: visible;
    height: auto;
    width: auto;
  }
  .firstlesson-head-mobile {
    display: block !important;
  }
  table.orarend td .btn-yellow {
    font-size: 8px;
    padding: 5px 5px;
  }
}
@media only screen and (max-width: 321px) and (orientation: landscape) {
  .events-mobile {
    display: block;
  }
  .events-mobile .banner-item img {
    max-width: 100% !important;
    width: 100%;
  }
  .highlight-services .service-item + .service-item {
    margin-top: 20px;
  }
  .hero-mobile .carousel-inner > .item > a {
    display: block;
    text-align: center;
  }
  .hero-mobile .carousel-inner > .item > a > img,
  .hero-mobile .carousel-inner > .item > img {
    height: 300px;
    z-index: 150;
    display: inline-block;
  }
  .nomobile {
    display: none !important;
  }
  .mobile {
    display: inline-block !important;
    visibility: visible;
    height: auto;
    width: auto;
  }
  table.orarend td .btn-yellow {
    font-size: 8px;
    padding: 5px 5px;
  }
}
