/*
  Top navigation + logo styles
*/
.site-top-container{
  position: relative;
  background: #f2f2f2;
  padding-top: 95px;
}

.popup-navigation-page-shade{
  background: rgba(0,0,0,0.4);
  width:100%;
  height:100%;
  position: fixed;
  display: none;
  z-index: 50;
  top:0px;
}

.site-top{
  padding-top: 10px;
  background: #f2f2f2;
  padding-bottom: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 49;
  transition: all 0.2s;

  .container{
    position: relative;
  }

  .logo-container{
    .logo{
      float:left;
      padding-right: 15px;
    }
    .info{
      height: 55px;
      display: inline-block;
      border-left: 1px solid #aaa;
      padding-top: 5px;
      padding-left: 15px;
      font-size: 14px;

      a {
        color:#aaa;
      }

    }
  }

  .covid19{
    text-align: right;
    padding: 5px 10px 0px 10px;
    img{
      max-height: 25px;
    }
  }

  .menubars{
    font-size: 22px;
    color:#333;

    small{
      font-size: 16px;
      line-height: 20px;
      text-transform: uppercase;
      padding-right: 5px;
      display: inline-block;
    }
  }

  .firstlesson-head{
    width:169px;
    height:89px;
    position: absolute;
    top: 0px;
    right:10px;
    background:url('../images/ingyenes-ora/head-btn.png');
    z-index: 60;
    opacity: 0.8;
    -webkit-filter: grayscale(20%);
    filter: grayscale(20%);

    a{
      width:100%;
      height: 100%;
      display: block;
    }
  }
  .firstlesson-head:hover{
    opacity: 1;
    -webkit-filter: grayscale(0%);
    filter: grayscale(0%);
  }

  .firstlesson-head-mobile{


    .info {
      border-top: 1px solid #aaa;
      padding-left: 20px;
      line-height: 30px;
      a{
        font-size: 12px;
        color:#aaa;
      }
    }
    .firstlesson-head-btn {
      width:171px;
      height:61px;
      position: absolute;
      top: 1px;
      right:15px;
      background:url('../images/ingyenes-ora/head-btn-mobile.png');
      z-index: 60;

      a{
        width:100%;
        height: 100%;
        display: block;
      }
    }
  }
  .firstlesson-head-mobile:hover{
  }

}

.site-top.scrolled{
  padding-top: 10px;
  padding-bottom: 10px;
}

.site-top.scrolled:after {
  content:"";
  position:absolute;
  width:100%;
  bottom:1px;
  z-index:49;
  box-shadow: 0px 0px 8px 2px #aaa;
}

// Popup nav container
.popup-nav-container{
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 51;
  display: none;


  .popup-navigation{
    margin-top:-70px;
    display: none;
    width: 100%;
    border-radius: 15px;
    background: #f2f2f2;
    -webkit-box-shadow: 0px 0px 20px 1px rgba(110,110,110,0.8);
    -moz-box-shadow: 0px 0px 20px 1px rgba(110,110,110,0.8);
    box-shadow: 0px 0px 20px 1px rgba(110,110,110,0.8);

    left:0px;
    top:0px;
    z-index: 100;
    float:left;

    .nav-head{
      width:100%;
      height: 85px;
      background: #fff;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;

      .logo-cont{
        padding:20px 0px 0px 20px;
        width: 30%;
        float:left;
        text-align: left;
      }
      .btn-cont{
        padding:20px 20px 0px 0px;
        width: 30%;
        float:right;
        text-align: right;

        a{
          font-size: 32px;
          display:inline-block;
          margin-left: 20px;
        }
        a.facebook{
          color:#2d41a2;
        }
        a.map{
          color:#fd3535;

        }
        a.popup-navigation-close{
          color:#c3c3c3;
          margin-left: 35px;
          font-size: 40px;
        }
        a.popup-navigation-close:hover{
          color:#aaa;
        }
      }
    }
    .nav-body{
      width: 100%;
      min-height: 185px;
      height: auto;
      padding-top: 30px;

      .title{
        color:#5e5e5e;
        font-size: 20px;
        font-family:@titleFont;
        padding-top:0px;
        padding-bottom: 10px;
        margin-top:0px;
        border-bottom: 1px dashed #aaa;
      }

      .menuitem{
        font-family:@titleFont;
        width: 95%;
        font-weight: normal;
        display:block;
        font-size: 20px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        color:#a5a5a5;
      }
      .menuitem.dance{
        font-family:@titleFont;
        font-weight: normal;
        width: 95%;
        display:block;
        font-size: 20px;
        padding-bottom: 2px;
        margin-bottom: 13px;
        line-height: 25px;
        color:#a5a5a5;
      }
      .menuitem:hover{
        color:#ffc536;
        text-decoration: none;
      }

      .submenuitem{
        font-family:@titleFont;
        display:block;
        font-size: 12px;
        color:#a5a5a5;
      }

      .submenuitem:hover{
        color:#ffc536;
        text-decoration: none;
      }

      ul{
        padding-left:15px;
      }

      ul.dances{
        list-style-type:none;

        a{
          font-family:@titleFont;
          font-size: 22px;
          padding-bottom: 10px;
          margin-bottom: 10px;
          color:#5e5e5e;
        }
      }

      .mainbanner{
        width:100%;
        .banner-item{
          margin-top:10px;
        }
        img{
          // max-width:227px;
        }
      }

      .popupnav-social-container{
        margin: 20px 0;
        text-align: center;
        font-size: 30px;
        display: none;

        .social-item{
          display: inline-block;
          margin: 0 20px;

          a{
            display: block;
            width: 100%;
            height: 100%;
          }

          .facebook{
            color: #30402a;
          }
          .location{
            color: #ff3636;
          }
          .youtube{
            color: #ff3636;
          }
          .instagram{
            color: #666;
          }
        }
      }

    }
  }
}