/*
  Application main style
*/
* {
  box-sizing: border-box;

}
*:focus {
  outline: none;
}

body{
  // background:#fff;
  background:#F2F2F2;
}

h1,h2,h3,h4,h5{
  font-family: @titleFont;
  padding-bottom:20px;
}

@media (min-width: 1000px) {
  .container{
    width: 955px;
  }
}

@-moz-document url-prefix() {
  fieldset { display: table-cell; }
}
/*
  Import less styles
*/
// @import 'fonts';
@import 'mixins';
@import 'variables';
@import 'globalstyles';
@import 'top-navigation';
@import 'social-links';
@import 'startpage-hero';
@import 'hero-dance-types';
@import 'hero-navigation';
@import 'subhero-navigation';
@import 'startpage-start-here';
@import 'startpage-services';
@import 'buttons';
@import 'helpers';
@import 'content';
@import 'instructor';
@import 'footer';
@import 'form-elements';
@import 'discount-ul';
@import 'orarend';
@import 'price-calculator-component';
@import 'fellepesek';
@import 'contact-info-box';
@import 'events';
@import 'faq';
@import 'helyszinek';
@import 'gallery';
@import 'dancepage';
@import 'dancepage-nav';
@import 'front-page-right-hover';
@import 'notification';

@import 'app-first-lesson-reg';
@import 'app-new-lesson-reg';



@import 'styles-mobile';