/*
  Buttons
*/

.topmargin10 {
  margin-top:10px;
}

.topmargin20 {
  margin-top:20px;
}

.block {
  display:block;
}
.bottompadding10 {
  padding-bottom:10px;
}
.bottompadding20 {
  padding-bottom:20px;
}

.toppadding20 {
  padding-top:20px;
}

.topshadow{
  -moz-box-shadow:    inset  0  10px 20px -10px #aaa;
  -webkit-box-shadow: inset  0  10px 20px -10px #aaa;
  box-shadow:        inset  0  10px 20px -10px #aaa;
}

.bottomshadow{
  -moz-box-shadow:    inset  0 -10px 20px -10px #aaa;
  -webkit-box-shadow: inset  0 -10px 20px -10px #aaa;
  box-shadow:        inset  0 -10px 20px -10px #aaa;
}

a.full-clickable{
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.btn-max{
  width: 100%;
  padding: 40px;
  font-size: 145%;
}

.text-hightlight{
  font-family: @titleFont;
  color: @colorYellow;
}
.text-mid{
  font-size: 20px;
}
.text-lg{
  font-size: 24px;
}

.font-weight-bold{
  font-weight: bold;
}
