/*
  Buttons
*/

.contact-info {
  border-top:1px dashed #a6a6a6;
  border-bottom:1px dashed #a6a6a6;
  padding: 20px 10px;
  margin: 0px 0px;

  a{
    font-family: @titleFont;
    letter-spacing: 1px;
    font-size: 18px;
    color: #a6a6a6;      
  }

}
