/*
  Application global style
*/
a.highlight{
  display: inline-block;
  color: @colorYellow;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: @contentFont;
  margin-top:5px;
  text-decoration: underline;
  font-weight: 400;
}

a.highlight:hover{
  text-decoration: none;

}

a.highlight-dark{
  display: inline-block;
  color: @colorYellow;
  font-size: 16px;
  background: #999;
  padding: 5px 20px 5px 20px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: @contentFont;
  margin-top:5px;
  text-decoration: underline;
  font-weight: 400;
}

a.highligh-dark:hover{
  text-decoration: none;

}
